/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useLayoutEffect, useReducer, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { useFeedbackHandler, useGetCurrencies, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import { Storage } from '+services/storage-services';
import { ErrorType, FilterOption, IMetaData, moduleType, ParamType } from '+types';
import {
  backwardAmountInput,
  camelCaseToWords,
  capitalizeRemovedash,
  cleanInput,
  convertCamelToTitleCase,
  filteredOutObjectProperty,
  filterOutEmptyValues,
  getDateQuery,
  logError,
  queriesParams,
  returnUniqueItemsInArray,
  selectedArrayItem
} from '+utils';

import { getFilterParams } from './data/Filter';
import Modal from './Modal';
import ToggleFormGroup from './ToggleFormGroup';

import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';

const api = new APIRequest();
const MIN_DATE = new Date('01/01/2016');
const MAX_DATE = new Date();

type InitialStateType = {
  status: string[];
  selectData: Record<string, unknown>;
  type: 'payin' | 'payout' | string;
  clear: boolean;
};

const initialState: InitialStateType = {
  status: [],
  selectData: {},
  type: '',
  clear: false
};

interface AdvancedFilterProps {
  type: moduleType;
  visible: boolean;
  close: () => void;
  filterData: FilterOption[];
  loadedData: ParamType;
  handleFilterQuery: (data: ParamType) => void;
  activeCurrency: string;
  queryKeyword: string;
  setQueryKeyword: (x: string) => void;
  queryAmount: string;
  setQueryAmount: (x: string) => void;
  heading: string;
  description: string;
  filterIsCleared: boolean;
}

const AdvanceFilterModal = ({
  type,
  visible = true,
  close,
  loadedData,
  queryAmount,
  setQueryAmount,
  queryKeyword,
  setQueryKeyword,
  activeCurrency,
  filterData,
  handleFilterQuery,
  heading,
  description,
  filterIsCleared
}: AdvancedFilterProps): JSX.Element => {
  const { feedbackInit } = useFeedbackHandler();
  const { data: currencyData } = useGetCurrencies(Storage);
  const payinTypes = ['pay-in', 'settlements', 'refunds'];
  const searchQuery = useSearchQuery();

  const [rangedDates, setRangedDates] = useState<Record<string, string>>({});
  const [state, setState] = useReducer((prev: InitialStateType, next: Partial<InitialStateType>) => {
    if (next?.clear) return initialState;
    return { ...prev, ...next };
  }, initialState);

  type stateType = keyof typeof state;

  const [errors, setErrors] = useReducer((prev: ErrorType, next: ErrorType) => ({ ...prev, ...next }), {});

  useLayoutEffect(() => {
    filterData?.forEach((option: FilterOption) => {
      setState({ [option.type]: option.type === 'status' ? [] : option?.default ?? '' });
    });
  }, []);

  useEffect(() => {
    if (!loadedData) return;
    if (!(Object.keys(loadedData) || []).length || filterIsCleared) {
      setState({ clear: true });
      return;
    }

    setState({
      ...state,
      ...loadedData,
      status:
        typeof loadedData.status === 'string'
          ? returnUniqueItemsInArray([loadedData.status, ...(state?.status || [])])
          : returnUniqueItemsInArray([...((loadedData?.status as []) || []), ...(state?.status || [])])
    });
  }, [loadedData]);

  useEffect(() => {
    if (filterIsCleared) {
      setState({ clear: true });
    }
  }, [filterIsCleared]);

  // APIs to fetch data
  const errorHandler = (error: AxiosError, message?: string) => {
    logError(error);
    feedbackInit({
      title: `Advanced Filter(${capitalizeRemovedash(type)})`,
      message: message ?? (error.response?.data as { message: string })?.message,
      type: 'danger',
      componentLevel: true
    });
    throw error;
  };

  const { data: paymentProcessors } = useQuery(
    `${payinTypes.includes(type) ? 'PAYIN' : 'PAYOUT'}_PROCESSOR_LIST`,
    () => api.getProcessorList({ category: payinTypes.includes(type) ? 'payin' : 'payout' }),
    {
      staleTime: 30 * 60 * 1000,
      onError: (e: AxiosError) => errorHandler(e, 'There has been an error fetching the processor list.')
    }
  );

  // Reactivity
  useEffect(() => {
    if (type === 'pay-outs') {
      setState({
        selectData: {
          payoutsType: currencyData?.find(c => c.code === activeCurrency)?.meta_data?.payment_types?.[
            (state.type as keyof IMetaData['payment_types']) || ''
          ]
        }
      });
    }
  }, [state.type]);

  const filterTransactions = () => {
    let newState = { ...state };
    if (doErrorsExist()) {
      feedbackInit({
        message: 'Please enter correct values',
        type: 'warning',
        componentLevel: true
      });
      return;
    }

    if (Object.entries(searchQuery.value).length > 0) {
      newState = { ...searchQuery.value, ...newState };
    }

    const filterQueries = filteredOutObjectProperty(filterOutEmptyValues(getFilterParams(newState, type)), [
      queriesParams.amountRange,
      queriesParams.dateCreatedTo,
      queriesParams.dateCreatedFrom,
      queriesParams.selectData,
      ['refunds', 'settlements', 'settlements-payouts'].includes(type) ? queriesParams.amountSubfilter : ''
    ]);
    handleFilterQuery(filterQueries);
    close();
  };

  const handleToggleDefaultState = (option: FilterOption) => {
    const { elementType, type: currentType } = option;
    const options = {
      status: state.status?.length > 0,
      amount: Number(queryAmount) > 0,
      date: state[`${currentType}From` as stateType] || state[`${currentType}To` as stateType],
      'date-range': state[`${currentType}From` as stateType] || state[`${currentType}To` as stateType]
    };
    return options[elementType as keyof typeof options] ?? !!state[currentType as stateType];
  };

  const doErrorsExist = () => {
    const actualErrors = filterOutEmptyValues(errors);
    const errorKeys = Object.keys(actualErrors);
    if (!errorKeys.length) {
      return false;
    }

    let exists = false;
    errorKeys.forEach(key => {
      if (handleToggleDefaultState(key as unknown as FilterOption)) {
        exists = true;
      }
    });
    return exists;
  };

  const resetToggleGroup = (option: FilterOption) => {
    const relatedQueries = Object.keys(state).filter(key => key.includes(option.type));
    relatedQueries.forEach(key => {
      delete state[key as stateType];
      delete searchQuery.value[key as keyof InitialStateType];
    });
    if (relatedQueries.includes('amount')) {
      setQueryAmount('');
    }

    const newState = {
      ...state,
      [option.type]: option.type === 'status' ? [] : option?.default ?? '',
      [`${option.type}From`]: option.default ?? '',
      [`${option.type}To`]: option.default ?? ''
    };
    setState(filteredOutObjectProperty(filterOutEmptyValues({ ...searchQuery.value, ...newState }), relatedQueries));
  };

  const content = () => (
    <div className="row advance-modal px-3">
      {filterData?.map((option: FilterOption): JSX.Element => {
        const amountSubfilterKey = option.elementType === 'amount' ? option.subFilterKey || 'amountRange' : '';
        const rangedDate = rangedDates[option.type];
        const dateFrom = state?.[`${option.type}From` as stateType];
        const dateTo = state?.[`${option.type}To` as stateType];

        return (
          <ToggleFormGroup
            key={option.type}
            hidden={option.hide?.(state)}
            reset={() => resetToggleGroup(option)}
            defaultOpen={handleToggleDefaultState(option)}
            label={`${
              option.elementType === 'reference'
                ? option.referenceLabel
                : option.label || capitalizeRemovedash(camelCaseToWords(option.type))
            } ${option.elementType === 'amount' ? `(${activeCurrency})` : ''}`}
          >
            {/* Status type */}
            {option.elementType === 'status' && (
              <div className="d-flex flex-wrap">
                {['All', ...(option.statusOptions || [])].map((opt: string, index: number) => (
                  <div className="mr-4" key={opt}>
                    <div className="form-check mb-2">
                      <label className="form-check-label checkbox-text">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={
                            opt === 'All' ? state.status?.length === (option.statusOptions?.length || 0 + 1) : state.status?.includes(opt)
                          }
                          onChange={e => {
                            const data = opt === 'All' ? option.statusOptions : selectedArrayItem(opt, state.status || []);
                            return setState({ status: e.target.checked ? data : selectedArrayItem(opt, state.status || []) });
                          }}
                        />
                        {capitalizeRemovedash(opt)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Amount type */}
            {option.elementType === 'amount' && (
              <div className="row">
                {option.amountRangeOptions && (
                  <div className="form-group col-sm-3">
                    <select
                      className="form-control"
                      onChange={e => setState({ [amountSubfilterKey]: e.target.value })}
                      value={String(state[amountSubfilterKey as stateType])}
                    >
                      {option.amountRangeOptions?.map((opt: string, index: number) => (
                        <option key={opt} value={opt}>
                          {option.convertFromCamelCase ? convertCamelToTitleCase(opt) : capitalizeRemovedash(opt)}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <div className="form-group col-sm-6">
                  <input
                    className="form-control"
                    inputMode="numeric"
                    type="text"
                    placeholder="0.00"
                    maxLength={50}
                    onChange={e => {
                      setQueryAmount(parseFloat(String(backwardAmountInput(e.target.value))).toFixed(2));
                      if (option.amountRangeOptions && !state[amountSubfilterKey as stateType]) {
                        setState({ [amountSubfilterKey]: option.amountRangeOptions[0] });
                      }
                      setState({
                        [option.type]: backwardAmountInput(e.target.value)
                      });
                    }}
                    value={String(state[option.type as stateType] ?? '') || ''}
                  />
                </div>
                <strong className="form-group col-sm-3 pt-2">{activeCurrency}</strong>
              </div>
            )}

            {/* Text type */}
            {option.elementType === 'text' && (
              <>
                <input
                  className="form-control mb-2"
                  type="text"
                  minLength={option.minLength}
                  maxLength={option.maxLength}
                  placeholder={option.inputPlaceholder || 'Merchant name or ID'}
                  value={String(state[option.type as stateType] ?? '')}
                  onChange={e => {
                    const value = cleanInput(e.target.value);
                    setState({ [option.type]: value });
                    if (option.validate) {
                      const errMsg = option.validate?.(value);
                      setErrors({ [option.type]: errMsg || '' });
                    }
                  }}
                />
                <div className="info-detail-box bg-transparent px-0 mb-0" hidden={!option.inputPostScript}>
                  {option.inputPostScript}
                </div>
              </>
            )}
            {/* Select type */}
            {option.elementType === 'select' && (
              <select
                className="form-control mb-3"
                onChange={e => setState({ [option.type]: e.target.value })}
                value={state[option.type as stateType] as string}
              >
                <option value="">All</option>
                {option.type === 'processor' &&
                  paymentProcessors?.data?.map((processor: { slug: string; name: string }) => (
                    <option key={processor?.slug} value={processor?.slug}>
                      {processor?.name}
                    </option>
                  ))}
                {option.type !== 'processor' &&
                  ((option?.externalStateData ? state.selectData?.[option.externalStateData] || [] : option.selectOptions || []) as []).map(
                    (item: { name: string; value: string }) => (
                      <option key={item.value} value={item.value}>
                        {item.name}
                      </option>
                    )
                  )}
              </select>
            )}
            {/* Date type */}
            {option.elementType === 'date' && (
              <div className="row">
                <div className="form-group col-lg-6 col-md-6">
                  <select
                    className="form-control"
                    onChange={e => {
                      const today = dayjs().format('YYYY-MM-DD');
                      setRangedDates({ ...rangedDates, [option.type]: e.target.value });
                      setState({
                        ...(!option.processDate
                          ? { [option.type]: today, [`${option.type}Range`]: e.target.value }
                          : {
                              [`${option.type}From`]: getDateQuery(e.target.value, 'From', today),
                              [`${option.type}To`]: getDateQuery(e.target.value, 'To', today)
                            })
                      });
                    }}
                    value={rangedDate}
                  >
                    <option value="">- Select a date range -</option>
                    {option.dateRangeOptions?.map((item: string) => (
                      <option key={item} value={item}>
                        {capitalizeRemovedash(item)}
                      </option>
                    ))}
                  </select>
                </div>

                <div
                  className="form-group col-lg-6 col-md-6"
                  hidden={[undefined, 'today', 'last_seven_days', 'last_thirty_days'].includes(rangedDate)}
                >
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={date => {
                      setState({
                        ...(option.processDate && {
                          [`${option.type}From`]: getDateQuery(rangedDate, 'From', date as unknown as string),
                          [`${option.type}To`]: getDateQuery(rangedDate, 'To', date as unknown as string)
                        }),
                        [option.type]: date
                      });
                    }}
                    selected={state?.[option.type as stateType] as unknown as Date}
                    placeholderText={option?.datePickerPlaceholder}
                    calendarClassName="custom-datepicker"
                    {...option?.datePickerProps}
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                  />
                </div>
              </div>
            )}

            {/* Date Range type */}
            {option.elementType === 'date-range' && (
              <div className="row">
                <div className="form-group col-lg-6 col-md-6">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={date => {
                      setState({
                        [`${option.type}From`]: getDateQuery('', 'From', date as unknown as string)
                      });
                    }}
                    maxDate={dateTo ? new Date(dateTo as string) : new Date()}
                    selected={dateFrom ? new Date(dateFrom as string) : new Date()}
                    placeholderText="From"
                    calendarClassName="custom-datepicker"
                    {...option?.datePickerProps}
                    minDate={MIN_DATE}
                  />
                </div>

                <div className="form-group col-lg-6 col-md-6">
                  <DatePicker
                    dateFormat="dd/MM/yyyy"
                    onChange={date => {
                      setState({
                        [`${option.type}To`]: getDateQuery('', 'To', date as unknown as string)
                      });
                    }}
                    minDate={dateFrom ? new Date(dateFrom as string) : new Date()}
                    selected={dateTo ? new Date(dateTo as string) : new Date()}
                    placeholderText="To"
                    calendarClassName="custom-datepicker"
                    {...option?.datePickerProps}
                    maxDate={MAX_DATE}
                  />
                </div>
              </div>
            )}

            {/* Reference type */}
            {option.elementType === 'reference' && (
              <div className="row">
                <div className="form-group col-sm-4">
                  <select
                    className="form-control"
                    name="column"
                    onChange={e => setState({ [`${option.type}Subfilter`]: e.target.value })}
                    value={state[`${option.type}Subfilter` as stateType] as string}
                  >
                    <option value="contains">Contains</option>
                    <option value="is_exactly">Is Exactly</option>
                  </select>
                </div>

                <div className="form-group col-sm-8">
                  <input
                    name="transaction-id"
                    className="form-control"
                    type="search"
                    placeholder={option.referencePlaceholder}
                    value={queryKeyword || (state[option.type as stateType] as string)}
                    onChange={e => {
                      setQueryKeyword(cleanInput(e.target.value));
                      setState({
                        [option.type]: cleanInput(e.target.value),
                        ...(!(state[`${option.type}Subfilter` as stateType] as string) && { [`${option.type}Subfilter`]: 'contains' }),
                        ...(!e.target.value?.trim() && { [`${option.type}Subfilter`]: '' })
                      });
                    }}
                  />
                </div>
              </div>
            )}

            <div className="mb-1">
              <div className="has-error-label error-msg">{errors[option.type]}</div>
            </div>
          </ToggleFormGroup>
        );
      })}
    </div>
  );

  return (
    <Modal
      size="md"
      close={close}
      heading={heading || 'Filter Transactions'}
      description={description || 'Find more transactions using any of the following advanced filter controls.'}
      content={content()}
      visible={visible}
      firstButtonText="Close"
      secondButtonText="Apply Filter"
      secondButtonAction={filterTransactions}
      secondButtonActionIsTerminal={false}
    />
  );
};

export default AdvanceFilterModal;
