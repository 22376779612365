import React from 'react';
import { Link } from 'react-router-dom';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import './index.scss';

type EventT = {
  title: string;
  value: string | number;
  description: string;
  cta: {
    label: string;
    link: string;
  };
};

interface IEventsSummaryProps {
  events: EventT[];
  isFetching?: boolean;
}

export default function EventsSummary({ events, isFetching }: IEventsSummaryProps) {
  return (
    <div className="ies">
      {events?.map((event, index) => (
        <div key={index} className="ies__event">
          {isFetching ? (
            <LoadingPlaceholder type="text" content={2} />
          ) : (
            <>
              <div className="ies__event-title">{event.title}</div>
              <div className="ies__event-value">{event.value}</div>
              <div className="ies__event-description">{event.description}</div>
              <Link to={event.cta.link} className="ies__event-cta">
                {event.cta.label} <i className="os-icon os-icon-arrow-up-right" />
              </Link>
            </>
          )}
        </div>
      ))}
    </div>
  );
}
