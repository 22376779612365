import React, { useReducer } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import { logError } from '+utils';

import Categories from './EmailCategories';
import EmailConfigurationForm from './EmailCategoriesForm';

import '../index.scss';

interface IEmailConfigState {
  route: string;
}

const baseURL = process.env.REACT_APP_MIDDLEWARE_API_BASE;
const apiRequest = new APIRequest(baseURL);

function EmailConfiguration() {
  const { feedbackInit } = useFeedbackHandler();
  const { id } = useParams() as { id: string };
  const { data } = useQuery(
    ['SINGLE_MERCHANT', id],
    () => {
      return apiRequest.getMerchant(id);
    },
    {
      onError: error => {
        logError(error);
        feedbackInit({
          message: `There has been an error getting this merchant's details`,
          type: 'danger',
          action: undefined,
          statusCode: undefined,
          componentLevel: undefined,
          callback: undefined,
          title: undefined
        });
      }
    }
  );
  const { data: notify } = useQuery(['NOTIFYT_notify', id], () => apiRequest.getNotificationConfiguration(id), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error getting merchant settlement summary',
        type: 'danger',
        action: undefined,
        statusCode: undefined,
        componentLevel: undefined,
        callback: undefined,
        title: undefined
      });
    }
  });

  const configuration = notify?.data?.configuration || {
    payin: null,
    payout: null,
    dispute: null,
    card_issuance: null,
    product_and_marketing: null
  };

  const [state, updateState] = useReducer(
    (prev: IEmailConfigState, next: Partial<IEmailConfigState>) => {
      return { ...prev, ...next };
    },
    {
      route: 'Marketing & Product Updates'
    }
  );

  return (
    <>
      <div className="mt-4 content-wrapper">
        <div className="row">
          <div className="col-sm-12">
            <div className="element-wrapper">
              <div className="element-box p-0">
                <h5 className="form-header">Email Notifications</h5>
                <div className="form-desc p-0 border-bottom-0 form-desc-text ">
                  See how this merchant receives and manages their email notifications. Kora may still send important notifications for this
                  account outside their notification settings.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-5 mb-2">
            <div className="form-group">
              <label htmlFor="refund-amount" className="withdraw-label">
                <span className="dark">Default Email Address(es) for Notifications</span>
              </label>
              <input value={data?.email} className="form-control email-default" disabled />
            </div>
          </div>
        </div>
      </div>

      <div className="pt-4">
        <div className="category-heading">Email categories</div>
        <div className="row mt-3">
          <div className="col-md-3">
            <Categories currentRoute={state.route} setRoute={updateState} />
          </div>
          <div className="col-md-9">
            <EmailConfigurationForm route={state.route} configuration={configuration} defaultMerchant={data} />
          </div>
        </div>
      </div>
    </>
  );
}

export default EmailConfiguration;
