import { useEffect } from 'react';

import { Storage } from '+services/storage-services';

import useReducerState from './useReducerState';

interface State {
  openTransactionStatusModal?: boolean;
  activeTransaction?: any;
  clickedTransactionIds?: any[];
}

const useTransactionStatus = () => {
  const [state, setState] = useReducerState<State>({
    openTransactionStatusModal: false,
    activeTransaction: {},
    clickedTransactionIds: []
  });

  const storedTransactionIds = Storage.getItem('storedTransactionIds') || [];
  useEffect(() => {
    if (storedTransactionIds.length > 0) {
      setState({
        ...state,
        clickedTransactionIds: storedTransactionIds
      });
    }
  }, []);

  const updateTransactionStatusModalState = (isOpen: boolean, transaction: any) => {
    setState({
      openTransactionStatusModal: isOpen,
      activeTransaction: transaction
    });
  };

  const handleProcessingLoader = (transRef: any) => {
    const updatedTransactionIds = [...storedTransactionIds, transRef];
    Storage.setItem('storedTransactionIds', JSON.stringify(updatedTransactionIds));
    setState({
      clickedTransactionIds: updatedTransactionIds
    });
  };

  return {
    state,
    updateTransactionStatusModalState,
    handleProcessingLoader
  };
};

export default useTransactionStatus;
