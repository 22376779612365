import React from 'react';

import { useGetCurrencies } from '+hooks';
import { Storage } from '+services/storage-services';
import TabSwitch from '+shared/TabSwitch';
import { storageDataKey } from '+utils';

import './index.scss';

interface ICurrencyPickerProps {
  options?: string[];
  onChange: (currency: string) => void;
  activeCurrency: string;
  id?: string;
  className?: string;
  label?: React.ReactNode | string;
  bordered?: boolean;
  allowedOptions?: string[];
  showDropDown?: boolean;
}

const CurrencyPicker = ({
  options = [],
  onChange,
  activeCurrency,
  id = '',
  className = '',
  label = null,
  allowedOptions = [],
  showDropDown,
  bordered = false
}: ICurrencyPickerProps) => {
  useGetCurrencies(Storage);
  const availableCurrencies = Storage.getItem(storageDataKey.AVAILABLE_CURRENCIES);
  const actualOptions = options.length > 0 ? options : availableCurrencies;
  return (
    <div className={`currency-picker__comp ${className} ${bordered ? '__with-border' : ''}`}>
      <div>
        {label}
        <TabSwitch
          options={actualOptions}
          activeTab={activeCurrency}
          setTab={onChange}
          id={id}
          className="ml-3"
          maxVisibleOptions={4}
          allowedOptions={allowedOptions}
          showDropDown={showDropDown}
        />
      </div>
    </div>
  );
};

export default CurrencyPicker;
