import React, { ElementRef, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { ICloseOuterFunc, IHandleKeyboard, IListDropdownProps, IListItem } from '+types/listdropdown';

import RiskLevel from './RiskLevel';

import caret from '+assets/img/dashboard/caret.svg';
import search from '+assets/img/dashboard/search.svg';

import './ListDropdown.scss';

function ListDropdown({
  type,
  active,
  setActive,
  setValue,
  value,
  isFetching,
  list,
  className = '',
  defaultValue = '',
  searchDropdown = true,
  displayText = true,
  useId = false,
  useCoreId = false
}: IListDropdownProps) {
  const dropdownOverlayRef = useRef<ElementRef<'div'>>(null);
  const dropdownButtonRef = useRef<ElementRef<'button'>>(null);
  const dropdownMenuRef = useRef<ElementRef<'div'>>(null);
  const dropdownListRef = useRef<ElementRef<'ul'>>(null);
  const dropdownInputRef = useRef<ElementRef<'input'>>(null);
  const closeButtonRef = useRef<ElementRef<'button'>>(null);

  const [defaultText, setDefaultText] = useState(`Select a ${type?.toLowerCase()}`);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState(list);
  const [idLabel, setIdLabel] = useState('');

  const searchList = useDebouncedCallback((query: string, originalList: Array<IListItem>) => {
    if (query?.trim() === '') {
      setIsSearching(false);
      return;
    }
    setIsSearching(true);
    setSearchResults(() => {
      const newList = originalList.filter(resultItem => resultItem.name?.toLowerCase().includes(query.trim()?.toLowerCase()));
      return newList;
    });
  }, 800);

  useEffect(() => {
    const dropdownOverlay = dropdownOverlayRef.current;
    if (dropdownOverlay && active) {
      closeButtonRef.current?.focus();
      return;
    }
    dropdownButtonRef.current?.focus();
    setSearchQuery('');
    setIsSearching(false);
  }, [active]);

  useEffect(() => {
    if (defaultValue && defaultValue !== defaultText && value !== '') {
      setDefaultText(defaultValue);
    }
  }, [value]);

  useEffect(() => {
    if (list?.length && searchList) {
      searchList(searchQuery, list);
    }
  }, [searchQuery, list]);

  const handleKeyboard = useRef((e: IHandleKeyboard) => {
    const path = e?.path || e?.composedPath() || e.target;
    // Escape button closes the dropdown overlay
    if (e.keyCode === 27) {
      setActive(false);
      return;
    }
    // Handle searching on letter click
    if (dropdownOverlayRef?.current && !path.includes(dropdownInputRef?.current)) {
      // Handle adding text
      if (e.keyCode >= 65 && e.keyCode <= 90) {
        dropdownInputRef.current?.focus();
        return;
      }
    }
    // Spacebar and Down button open the tooltip
    if (e.target === dropdownButtonRef.current && (e.keyCode === 32 || e.keyCode === 40)) {
      setActive(true);
      return;
    }
    // Trapping tabbing inside overlay body
    if (dropdownOverlayRef?.current) {
      const lastItem = dropdownListRef.current?.childNodes[dropdownListRef.current.childNodes.length - 1].childNodes[0];
      // Tabbing backwards inside the overlay at the first focusable item
      if (e.shiftKey && e.keyCode === 9 && e.target === closeButtonRef.current) {
        dropdownListRef.current?.focus();
        return;
      }
      // Tabbing forward inside the overlay at the last focusable item
      if (e.keyCode === 9 && path.includes(dropdownListRef?.current) && e.target === lastItem) {
        closeButtonRef.current?.focus();
        return;
      }
    }
    // Keyboard Actions in Dropdown List
    if (path.includes(dropdownListRef?.current)) {
      // Spacebar on button selects the item and closes dropdown
      if (e.keyCode === 32) {
        const buttonValue = (e.target as HTMLInputElement).textContent ?? '';
        setValue(buttonValue);
        setActive(false);
        return;
      }
      // Up arrow key aids navigation
      if (e.keyCode === 38) {
        const currentListItem = (e.target as HTMLInputElement)?.parentNode;
        const previousOption = currentListItem?.previousSibling?.childNodes[0] as HTMLInputElement;
        if (!previousOption) {
          return;
        }
        previousOption.focus();
        return;
      }
      // Down arrow key aids navigation
      if (e.keyCode === 40) {
        const currentListItem = (e.target as HTMLInputElement)?.parentNode;
        const nextOption = currentListItem?.nextSibling?.childNodes[0] as HTMLInputElement;
        if (!nextOption) {
          return;
        }
        nextOption.focus();
      }
    }
  });

  const closeOuter = useRef((e: ICloseOuterFunc) => {
    e.preventDefault();
    const path = e?.path || e?.composedPath() || e.target;
    // Handles clicking on the overlay on mobile & clicking outside on desktop
    if (
      (e.target === dropdownOverlayRef?.current && !path.includes(dropdownMenuRef.current)) ||
      (dropdownOverlayRef?.current && !path.includes(dropdownOverlayRef.current) && !path.includes(dropdownButtonRef.current))
    ) {
      setActive(false);
    }
  });

  useEffect(() => {
    const closeOuterFunc = closeOuter.current;
    const handleKeyboardFunc = handleKeyboard.current;

    // Clicking outside closes the Dropdown
    window.addEventListener('click', closeOuterFunc as unknown as EventListener);
    window.addEventListener('keydown', handleKeyboardFunc as unknown as EventListener);

    return () => {
      window.removeEventListener('click', closeOuterFunc as unknown as EventListener);
      window.removeEventListener('keydown', handleKeyboardFunc as unknown as EventListener);
    };
  }, []);

  const generateResult = (listItem: string, query: string) => {
    const queryIndex = listItem?.toLowerCase().indexOf(query?.toLowerCase().trim());
    // If search paramater is at the beginning of the listItem string
    if (queryIndex === 0) {
      return (
        <>
          <strong>{listItem.substr(queryIndex, query.length)}</strong>
          {listItem.substr(queryIndex + query.length)}
        </>
      );
    }
    // If search paramater is at the end of the listItem string
    if (queryIndex === listItem?.length - query.length) {
      return (
        <>
          {listItem?.substr(0, queryIndex)}
          <strong>{listItem?.substr(queryIndex, query.length)}</strong>
        </>
      );
    }
    // If search parameter is within the book string
    if (queryIndex !== -1) {
      return (
        <>
          {listItem?.substr(0, queryIndex)}
          <strong>{listItem?.substr(queryIndex, query.length)}</strong>
          {listItem?.substr(queryIndex + query.length)}
        </>
      );
    }
    return listItem;
  };

  const searchingList = () => {
    const currentList = isSearching ? searchResults : list;
    return currentList?.length > 0 ? (
      currentList.map(resultItem => {
        let valueType: string;

        if (useId) {
          valueType = resultItem.id;
        } else if (useCoreId) {
          valueType = resultItem.kora_core_engine_id;
        } else {
          valueType = resultItem.name;
        }
        let selectedValue;
        if (useId || useCoreId) {
          selectedValue = valueType === value;
        } else {
          selectedValue = valueType?.toLowerCase() === value?.toLowerCase();
        }
        return (
          <li key={resultItem.id} role="option" aria-selected={selectedValue}>
            <button
              type="button"
              onClick={() => {
                setValue(valueType as string);
                setActive(false);
                if (useId || useCoreId) {
                  setIdLabel(resultItem.name);
                }
              }}
              className={selectedValue ? 'active' : ''}
            >
              {type === 'risk level' && <RiskLevel riskLevel={resultItem.code} hideTitle hideDivider className="mr-3" />}
              {searchQuery ? generateResult(resultItem.name, searchQuery) : resultItem.name}
              <span className="ml-2">{`- ${resultItem.id}`}</span>
            </button>
          </li>
        );
      })
    ) : (
      <li>
        <p>No results found</p>
      </li>
    );
  };

  return (
    <>
      <button
        type="button"
        onClick={() => {
          if (list?.length !== 0) {
            setActive(!active);
          }
        }}
        aria-haspopup="listbox"
        aria-disabled={list?.length === 0}
        ref={dropdownButtonRef}
        aria-expanded={active}
        className={`${className} list-dropdown--button ${active ? 'active' : ''} ${list?.length === 0 || isFetching ? 'disabled' : ''}`}
      >
        <span>{(useId || useCoreId ? idLabel : value) || defaultText}</span>
        {isFetching && <span className="sr-only">Loading</span>}
        <img src={caret} alt="caret icon" aria-hidden />
      </button>

      {active && (
        <div className="list-dropdown--overlay" ref={dropdownOverlayRef}>
          <div className="list-dropdown--menu" ref={dropdownMenuRef}>
            <button type="button" className="sr-only" ref={closeButtonRef} onClick={() => setActive(false)}>
              Close Dropdown Menu
            </button>
            {searchDropdown && (
              <div className="list-dropdown--search">
                <input
                  type="search"
                  aria-label={`Search ${type}s`}
                  placeholder={`Search ${type}s`}
                  className="list-dropdown--input"
                  ref={dropdownInputRef}
                  value={searchQuery}
                  onChange={e => setSearchQuery(e.target.value)}
                  onBlur={e => setSearchQuery(e.target.value)}
                />
                <img src={search} alt="search icon" aria-hidden />
              </div>
            )}
            <div className={`list-dropdown--header ${isSearching ? 'header--searching' : ''}`}>
              {!isSearching && displayText && (
                <button
                  type="button"
                  onClick={() => {
                    setValue('all');
                    setActive(false);
                  }}
                >
                  All {type}s
                </button>
              )}
            </div>
            <p className="sr-only" id="dropdown--list">
              {type}s list.
            </p>
            <ul className="list-dropdown--list" role="listbox" aria-label="dropdown-list" tabIndex={0} ref={dropdownListRef}>
              {searchingList()}
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default ListDropdown;
