import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSearchQuery } from '+hooks';
import Merchants from './Merchants';
import Verification from './Verification';
import MerchantDetails from './Merchants/MerchantDetails';
import VerificationEventDetails from './Verification/VerificationEventDetails';
import Overview from './Overview';
import './index.scss';

const tabs = [
  { name: 'Overview', key: 'overview' },
  { name: 'Merchants', key: 'merchants' },
  { name: 'Verification Events', key: 'verification' },
];

export default function Identity() {
  const searchQuery = useSearchQuery();
  const activeTab = searchQuery.value.tab || 'overview';
  return (
    <Switch>
      <Route
        path="/dashboard/identity"
        exact
        render={() => (
          <div className="content-i">
            <div className="content-box">
              <div className="os-tabs-w">
                <div className="os-tabs-controls os-tabs-complex">
                  <ul className="nav nav-tabs">
                    {tabs.map((tab) => (
                      <li className="nav-item">
                        <div
                          aria-expanded="false"
                          className={activeTab === tab.key ? 'nav-link active' : 'nav-link'}
                          data-toggle="tab"
                          onClick={() => searchQuery.setQuery({ tab: tab.key }, true)}
                          onKeyDown={() => searchQuery.setQuery({ tab: tab.key }, true)}
                        >
                          <span className="tab-label">{tab.name}</span>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
                <div>
                {activeTab === 'overview' && <Overview />}
                  {activeTab === 'merchants' && <Merchants />}
                  {activeTab === 'verification' && <Verification />}
                </div>
              </div>
            </div>
          </div>
        )}
      />
      <Route path="/dashboard/identity/merchants/:id" component={MerchantDetails} />
      <Route path="/dashboard/identity/verifications/:id" component={VerificationEventDetails} />
    </Switch>
  );
}
