/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useLocation, useParams } from 'react-router-dom';

import ChargebackActivityUpdate from '+dashboard/Disputes/Chargeback/components/ChargebackActivityUpdate';
import ChargebackDetailsModal from '+dashboard/Disputes/Chargeback/components/ChargebackDetailsModal';
import { useFeedbackHandler, useSetUserAccess, useTransactionStatus } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { ScrollToTopSection } from '+shared/ScrollToTop';
import ToolTip from '+shared/Tooltip';
import { mapContentToAction, PausedPaymentsModalContent } from '+shared/data/PausedPayments';
import PausedPaymentsModal from '+shared/Modal';

import useBulkActionStore from '+store/zustand';
import {
  capitalize,
  capitalizeFirst,
  capitalizeRemovedash,
  formatAmount,
  formatPAN,
  getDate,
  getTime,
  history,
  isAllowed,
  payinMethodMapping,
  payoutMethodMapping,
  specialisedDate,
  switchBank,
  switchCard,
  switchChannel,
  switchCurrency,
  switchStatus,
  switchTrxnMessage,
  truncateString
} from '+utils';

import Icon from '../Shared/Icons';
import TransactionStatusModal from '../Shared/TransactionStatus';
import ManageRefundsModal from './components/ManageRefundsModal';
import ReversalsInfoModal from './components/ReversalsInfoModal';
import { ChargebacksTab, RefundsTab, ReversalsTab } from './components/ReversalTabs';
import PayoutReversalsTab, { ReversePayoutModal } from './components/ReversalTabs/PayoutReversals';
import TrxBreakdownModal from './components/TrxBreakdownModal';

import mobileIconPNG from '+assets/img/dashboard/mobile.png';
import arrowRight from '+assets/img/dashboard/arrow-right-black.svg';
import cardIcon from '+assets/img/dashboard/card-icon.svg';
import InfoIcon from '+assets/img/dashboard/information-button.svg';
import mobileIconSVG from '+assets/img/dashboard/mobile.svg';
import walletIcon from '+assets/img/dashboard/wallet.svg';

import './index.scss';

const api = new APIRequest();
const pageSource = {
  webhookPage: 'web-hook'
};

function TransactionDetails() {
  const { id } = useParams();
  const { pathname, search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const origin = queryParams.get('origin');
  const { feedbackInit } = useFeedbackHandler();
  const transactionType = pathname.split('/').slice(-2)[0];
  const queryClient = useQueryClient();
  const [modalOpen, setModalOpen] = useState(false);
  const [manageRefundsModalOpen, setManageRefundsModalOpen] = useState(false);
  const [showReversalInitiateModal, setShowReversalInitiateModal] = useState(false);
  const [trxBreakdownModalOpen, setTrxBreakdownModalOpen] = useState(false);

  const [activeDisputeTab, setActiveDisputeTab] = useState(transactionType === 'payouts' ? 'reversals' : 'refunds');
  const [reversalsControls, setReversalsControls] = useReducer((prev, next) => ({ ...prev, ...next }), {
    data: null,
    isReversalError: false
  });

  const [chargebackModalState, setChargebackModalState] = useState({
    open: false,
    type: ''
  });
  const bulkInfo = useBulkActionStore((state) => state.bulkInfo);
  const savedBulkInfo = useRef(bulkInfo);
  const setBulkInfo = useBulkActionStore((state) => state.setBulkInfo);

  const switchCurrencyArray = Object.keys(switchCurrency);
  const [ngnCurrencyIndex, usdCurrencyIndex] = switchCurrencyArray;

  const [managePausedPaymentsModal, setManagePausedPaymentsModal] = useState('');
  const modalContent = mapContentToAction[managePausedPaymentsModal] || {};

  const switchAPI = {
    'pay-ins': () => api.getSingleTransaction('payins', id),
    payouts: () => {
      if (origin && origin === pageSource.webhookPage) {
        return api.getSingleTransactionFromWebhookServices('payouts', id, 'reference');
      }
      return api.getSingleTransaction('payouts', id);
    },
    refunds: () => api.getSingleRefund(id),
    'virtual-card': () => api.fetchACardTransaction(id),
    chargebacks: () => api.getSingleChargeback(id),
    'currency-exchange': () => api.getSingleSwap(id)
  };

  const userAccess = useSetUserAccess();
  const hasAccess =
    (isAllowed(userAccess, ['pay_in_details.view']) && transactionType === 'pay-ins') ||
    (isAllowed(userAccess, ['payout_details.view']) && transactionType !== 'pay-ins') ||
    (isAllowed(userAccess, ['refund_details.view']) && transactionType === 'refunds');
  const hasPayoutReversalAccess = isAllowed(userAccess, ['payout_reversals.create']);
  const hasRefundsUpdateAccess = isAllowed(userAccess, ['refunds.update']);

  useEffect(() => {
    if (userAccess && !hasAccess) {
      return history.push('/dashboard/access-denied');
    }
  }, [userAccess, transactionType]);

  const {
    data,
    isLoading,
    refetch: refetchTransaction
  } = useQuery(`TRANSACTION_DETAILS_${id}`, switchAPI[transactionType], {
    onError: () => {
      feedbackInit({
        message: `There has been an error fetching the details for the transaction: ${id.toUpperCase()}.`,
        type: 'danger'
      });
      history.goBack();
    },
    enabled: hasAccess
  });

  useEffect(() => {
    const isTrxPaused = data?.status === 'paused';
    if (isTrxPaused) {
      setBulkInfo({ data: [data] });
      return () => setBulkInfo(savedBulkInfo);
    }
    return () => null;
  }, [data?.status]);

  const formatReversalsData = reversalsData => {
    const buffer = {
      refunds: reversalsData?.filter(({ type }) => type === 'refund'),
      chargebacks: reversalsData?.filter(({ type }) => type === 'chargeback'),
      reversals: payoutReversal ? [payoutReversal] : reversalsData?.filter(({ type }) => type === 'overpayment' || type === 'underpayment')
    };
    setReversalsControls({ data: buffer });
  };

  const { refetch: refetchDisputes, isLoading: loadingDisputes } = useQuery(
    ['DISPUTES_LIST', activeDisputeTab],
    () => api.getPayinReversals(data?.reference),
    {
      enabled: transactionType === 'pay-ins' && data !== undefined,
      onSuccess: disputesData => {
        formatReversalsData(disputesData.reversals);
      },
      onError: () => {
        setReversalsControls({ isReversalError: true });
      }
    }
  );

  const { mutateAsync } = useMutation(() => api.unpausePayment({ references: [id], action: managePausedPaymentsModal.split('_')[0] }), {
    onSuccess: () => {
      queryClient.invalidateQueries(`TRANSACTION_DETAILS_${id}`);
      queryClient.invalidateQueries('PAUSED_PAYMENTS');
    },
    onError: (e) => {
      feedbackInit({
        message: e?.response?.data?.message || 'An error occured! Could not process paused payment',
        type: 'danger'
      });
    }
  });

  const {
    trace_id: traceId,
    reference,
    amount,
    message,
    amount_charged: amountCharged,
    payment_reversals: reversals,
    payout_reversal: payoutReversal,
    payment_source_type: paymentSourceType,
    payment_reversals_type: refundOrChargeback,
    amount_paid: netAmount,
    amount_collected: amountCollected,
    channel,
    transaction_date: createdAt,
    completed_at: completedAt,
    merchant_id: merchantID,
    currency,
    fee,
    vat,
    source,
    destination,
    payment,
    status,
    narration,
    merchant_bears_cost: merchantBearsCost,
    processor,
    processor_reference: processorReference,
    meta,
    metadata,
    account,
    payment_source,
    payment_source_reference: korapayReference,
    created_at,
    reversal_reason,
    status_reason,
    approved_amount,
    merchant,
    status_history,
    merchant_email,
    reason,
    cross_currency,
    description,
    type: cardTransactionType = '',
    card,
    card_acceptor_name,
    card_acceptor_country,
    date,
    external_reference: providerReference,
    unique_reference: uniqueReference,
    deadline
  } = data || {};

  const {
    source_amount,
    transaction_date: swapTransactionDate,
    converted_amount,
    account: merchantAccount,
    source_currency,
    destination_currency,
    reference: swapReference,
    status: swapStatus,
    exchange_rate,
    service_charge: conversionFee,
    channel: swapChannel,
  } = data?.data?.[0] || {};

  useEffect(() => {
    if (transactionType === 'payouts' || payoutReversal?.id) {
      formatReversalsData(reversals);
    }
  }, [data, payoutReversal]);

  useEffect(() => {
    if (transactionType === 'pay-ins' && data?.reference !== undefined) {
      refetchDisputes();
    }
  }, [transactionType, data?.reference]);

  const getBankImageByName = name => {
    return switchBank[name] || switchBank.default;
  };

  const mobileMoneyProvider = transactionType === 'payouts' ? destination?.details?.mobile_name : source?.details?.network_provider;
  const mobileMoneyNumber = transactionType === 'payouts' ? destination?.details?.mobile_number : source?.details?.mobile_number;
  const koraId = transactionType === 'payouts' ? uniqueReference : payment?.reference;
  const updateChargeback = () => {
    switch (transactionType) {
      case 'chargebacks':
        return (
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-primary"
              style={{ height: '30px', width: '150px' }}
              onClick={() => setChargebackModalState({ type: 'feedback', open: true })}
            >
              View History
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  const paymentChannels = item => (
    <>
      {item === 'wallet' && (
        <>
          <span>
            <img id="wallet-icon" src={walletIcon} alt="wallet icon" />
            <span style={{ display: 'inline', marginLeft: '0.5rem' }}>Korapay wallet</span>
          </span>
          <span>
            {transactionType === 'pay-ins' ? payment?.customer?.email || 'Not provided' : destination?.details?.email || 'Not provided'}
          </span>
        </>
      )}
      {item === 'card' && (
        <>
          {source?.details?.masked_pan ? (
            <>
              <span>
                <img
                  src={switchCard[source?.details?.card_type]}
                  className="card-logo"
                  alt="card-logo"
                  style={{ width: '1.5rem', marginRight: '8px' }}
                />
                <span style={{ display: 'inline' }}>{capitalize(source?.details?.card_type)}</span>
              </span>
              <span>
                {meta?.card_details?.first_six
                  ? formatPAN(`${meta?.card_details?.first_six}${source.details.masked_pan?.slice(6)}`)
                  : formatPAN(source.details.masked_pan)}
              </span>
            </>
          ) : (
            <span>
              <img src={cardIcon} className="card-logo" alt="card-icon" />
              <span style={{ display: 'inline' }}>No card information available</span>
            </span>
          )}
        </>
      )}
      {item === 'mobile_money' && (
        <>
          <span>
            <img
              id="phone-icon"
              src={mobileIconSVG}
              alt="phone icon"
              srcSet={mobileIconPNG}
              style={{ marginLeft: mobileMoneyProvider ? 0 : '-1.5rem', height: '23px', width: '13px' }}
            />
            <span style={{ display: 'inline', marginLeft: '.5rem', color: '#636C72' }}>
              {mobileMoneyProvider || 'Operator Information Unavailable'}
            </span>
          </span>
          {mobileMoneyNumber && <span>{`${mobileMoneyNumber}` || 'Not Available'}</span>}
        </>
      )}
      {item === 'bank_account' && (
        <>
          <span>
            <img
              id="bank-icon"
              src={getBankImageByName(destination?.details?.bank_name)}
              alt="bank"
              style={{ marginLeft: destination?.details?.bank_name ? 0 : '-1.5rem' }}
            />
            <span style={{ display: 'inline', marginLeft: '0.2rem', color: '#a9afbc' }}>
              {destination?.details?.bank_name || 'No Bank Information Available'}
            </span>
          </span>
          {destination?.details?.bank_name && <span>{destination?.details?.account_number || 'Not Available'}</span>}
        </>
      )}
    </>
  );

  const virtualCardsBusinessInformation = {
    business_name: card_acceptor_name || 'Not Available',
    business_country: card_acceptor_country || 'Not Available',
    business_email: 'Not Available'
  };

  const virtualAccount = () => {
    return (
      <>
        <p className="section-heading">Customer&apos;s Virtual Account</p>
        <p>
          <span>
            <img
              id="bank-icon"
              src={getBankImageByName(source?.details?.bank_slug)}
              alt="bank icon"
              style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
            />
            <span style={{ display: 'inline', marginLeft: 0, color: '#636C72' }}>
              {capitalizeRemovedash(source?.virtual_bank_account?.bank_name || 'No bank information Available')}
            </span>
          </span>
          {source?.virtual_bank_account?.bank_name && <span>{source?.virtual_bank_account?.account_number || 'Not Available'}</span>}
        </p>
        <p>
          <span>Account Name</span>
          <span>{capitalize(source?.virtual_bank_account?.account_name || 'Not Available')}</span>
        </p>
        {source?.virtual_bank_account?.expiry && (
          <p>
            <span>Expired At</span>
            <span>{`${getDate(source.virtual_bank_account.expiry)}, ${getTime(source.virtual_bank_account.expiry)}`}</span>
          </p>
        )}
      </>
    );
  };

  const ReversalTabs = () => {
    const sectionTabs = [
      { label: 'Refunds', key: 'refunds' },
      { label: 'Reversals', key: 'reversals' },
      { label: 'Chargebacks', key: 'chargebacks' }
    ];
    return (
      <li>
        <p className="section-heading refund-heading mt-5" id="section-title">
          Refunds, Reversals & Chargebacks
          <button type="button" onClick={() => setModalOpen(true)}>
            Learn more
            <img src={InfoIcon} alt="info" />
          </button>
        </p>
        <div className="rversal-rfund-cback">
          <div className="reversals-tabs-list" id="reversals-tabs-list">
            <div className="os-tabs-controls os-tabs-complex">
              <ul className="nav nav-tabs">
                {sectionTabs.map(({ label, key }) => {
                  const count = reversalsControls.data?.[key]?.length;
                  return (
                    <li className={`nav-item ${key === activeDisputeTab && 'active'}`} key={key}>
                      <button
                        id="tab"
                        type="button"
                        aria-expanded="false"
                        className={key === activeDisputeTab ? 'nav-link active' : 'nav-link'}
                        data-toggle="tab"
                        onClick={() => setActiveDisputeTab(key)}
                      >
                        <div>
                          <span className="tab-label">{label}</span>
                          {count ? <span style={{ marginLeft: '3px', fontWeight: '400' }}>({count})</span> : null}
                        </div>
                        <span className={`tab-icon ${key === activeDisputeTab && 'active'}`} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <span className="render-tabs">
            {activeDisputeTab === 'refunds' && (
              <RefundsTab
                data={reversalsControls.data?.refunds || []}
                currency={currency}
                isLoading={loadingDisputes}
                isError={reversalsControls.isReversalError}
                refetch={refetchDisputes}
              />
            )}
            {activeDisputeTab === 'reversals' && !payoutReversal && (
              <ReversalsTab
                data={reversalsControls.data?.reversals || []}
                currency={currency}
                isLoading={loadingDisputes}
                isError={reversalsControls.isReversalError}
                refetch={refetchDisputes}
              />
            )}
            {activeDisputeTab === 'chargebacks' && (
              <ChargebacksTab
                data={reversalsControls.data?.chargebacks || []}
                currency={currency}
                isLoading={loadingDisputes}
                isError={reversalsControls.isReversalError}
                refetch={refetchDisputes}
              />
            )}
            {activeDisputeTab === 'reversals' && payoutReversal && (
              <PayoutReversalsTab
                reversals={reversalsControls.data?.reversals || []}
                currency={currency}
                refetchTransaction={refetchTransaction}
                transaction={data}
              />
            )}
          </span>
        </div>
      </li>
    );
  };

  const moreDetailsOptions = {
    shared: {
      currency_charged: switchCurrency[currency] || switchCurrency[source_currency],
      'Korapay ID': <Copyable
        text={transactionType === 'currency-exchange' ? swapReference : koraId}
        textModifier={text => truncateString(text, 8)}
      /> || 'Not available',
      channel: switchChannel(transactionType === 'currency-exchange' ? swapChannel : channel)
    },
    common: {
      transaction_type: (
        <span>
          {transactionType === 'pay-ins'
            ? channel === 'web' && source?.type !== 'wallet'
              ? 'Deposit'
              : 'Pay-in'
            : channel === 'web'
              ? 'Withdrawal'
              : 'Payout'}
        </span>
      ),
      processor: `${processor || 'Not available'}`,
      'Processor ID': `${processorReference || 'Not available'}`,
      fee_bearer: `${merchantBearsCost ? 'Merchant' : 'Customer'}`,
      ...(!['refunds'].includes(transactionType) && {
        channel: switchChannel(channel)
      }),
      ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
      ...(meta?.receipt && { rnn: <Copyable text={meta?.receipt} /> }),
      ...(traceId ? { trace_id: traceId } : {}),
      ...(meta?.stan && { stan: <Copyable text={meta?.stan} /> }),
      approval_code: meta?.authorization_code ? <Copyable text={meta?.authorization_code} /> : 'Not Available',
      date_created: `${getDate(createdAt)}, ${getTime(createdAt)}`,
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
      payment_reversals_type: refundOrChargeback ? 'Yes' : 'None',
      ...(transactionType === 'payouts' ? { transaction_ID: <Copyable text={reference} /> } : {})
    },
    'virtual-card': {
      transaction_type: <span className="semibold">{capitalizeRemovedash(cardTransactionType)}</span>,
      processor: `${capitalize(card?.provider) || 'Not available'}`,
      "Processor's Transaction ID": providerReference ? <Copyable text={providerReference} /> : 'Not available',
      ...(cardTransactionType !== 'card_creation' && { description }),
      'cross-currency': cross_currency ? 'Yes' : 'No'
    },
    refunds: {
      ...(completedAt ? { date_completed: `${getDate(completedAt)}, ${getTime(completedAt)}` } : {}),
      processor: `${capitalizeRemovedash(payment_source?.processor || 'Not available')}`,
      processor_reference: `${payment_source?.processor_reference || 'Not available'}`,
      channel: `${switchChannel(payment_source?.channel) || 'Not available'}`,
      settlement_reference: `${payment_source?.settlement?.reference || 'Not available'}`
    },
    'currency-exchange': {
      status: (
        <>
          <span
            className={`status-pill smaller ${swapStatus === 'success' ? 'green' : switchStatus(swapStatus)}`}
            style={{ margin: '0 7px 5px 0' }}
          />
          {capitalize(swapStatus === 'success' ? 'Successful' : swapStatus || 'processing')}
        </>
      ),
      exchange_rate: (
        <>
        {`1 USD → ${exchange_rate} ${destination_currency !== 'USD' ? destination_currency : source_currency}`}
        </>
      ),
      description: <span className="__no-transaction-text">{capitalizeRemovedash(narration || 'No description provided')}</span>
    }
  };

  const morePaymentDetails = {
    ...moreDetailsOptions.shared,
    ...(moreDetailsOptions[transactionType] || moreDetailsOptions.common)
  };

  const merchantName = transactionType === 'payouts' ? account?.name : payment?.account?.name;

  const txBreakdownOptions = {
    common: {
      net: `${
        payment?.sentinal_transaction
          ? formatAmount(+netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee))
          : formatAmount(netAmount)
      } ${currency}`,
      fee: `${formatAmount(
        parseFloat(fee || '0') +
          parseFloat(vat || '0') +
          (+payment?.sentinal_transaction?.vat + +payment?.sentinal_transaction?.processing_fee || 0)
      )} ${currency}`,
      date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not Available',
      merchant: merchantName ? (
        <button type="button" className="merchant-name" onClick={() => history.push(`/dashboard/merchant/${merchantID}`)}>
          {merchantName}
        </button>
      ) : (
        'Not Available'
      ),
      description: `${channel === 'web' ? 'Balance Top Up' : narration || 'Not Available'}`
    },
    'virtual-card': {
      net_amount: `${formatAmount(amount)} ${currency}`,
      fee: `${formatAmount(parseFloat(fee || '0') + parseFloat(vat || '0'))} ${currency}`,
      'date_/_time': `${getDate(date)} ${getTime(date)}`,
      cardholder: `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
      'Transaction ID': (
        <>
          <Copyable text={reference?.toUpperCase?.()} textModifier={text => truncateString(text, 18)} />
          <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => {
              const cardType = card?.reserved ? 'reservedCards' : 'issuedCards';
              history.push(`/dashboard/card-issuance/${cardType}/${card?.reference}`);
            }}
          >
            <span>See Virtual Card</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    refunds: {
      refund_amount: `${formatAmount(amount) || 'Not available'}`,
      date_created: created_at ? `${getDate(created_at)}, ${getTime(created_at)}` : 'Not Available',
      merchant: <span>{capitalizeFirst(account?.name || 'Not Available')}</span>,
      amount_paid: `${formatAmount(payment_source?.amount) || 'Not available'}`,
      transaction_id: (
        <>
          <Copyable text={payment?.reference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
          <button
            type="button"
            className="btn btn-original"
            onClick={() => history.push(`/dashboard/pay-ins/${payment_source?.reference}`)}
          >
            <span>See original transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    chargebacks: {
      dispute_amount: `${formatAmount(amount)} ${currency}`,
      accepted_amount: `${formatAmount(approved_amount)} ${currency}`,
      rejected_amount: `${formatAmount(status === 'pending' ? 0 : +amount - +approved_amount)} ${currency}`,
      date_contested: `${getDate(created_at)}, ${getTime(created_at)}`,
      merchant: `${merchant}`,
      transaction_id: (
        <>
          <Copyable text={korapayReference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
          <button type="button" className="btn btn-original" onClick={() => history.push(`/dashboard/pay-ins/${korapayReference}`)}>
            <span>View Initial Transaction</span>
            <i className="os-icon os-icon-arrow-up-right" />
          </button>
        </>
      )
    },
    'currency-exchange': {
      amount_converted: `${formatAmount(source_amount)} ${source_currency}`,
      converted_to: `${formatAmount(converted_amount)} ${destination_currency}`,
      revenue: `${formatAmount(parseFloat(conversionFee || '0') + parseFloat(vat || '0'))} USD`,
      date_and_time: `${getDate(swapTransactionDate)}, ${getTime(swapTransactionDate)}`,
      merchant: <span style={{ color: 'rgb(35, 118, 243)' }}>{merchantAccount?.name}</span>,
      transaction_id: (
        <>
          <Copyable text={swapReference?.toUpperCase()} textModifier={text => truncateString(text, 18)} /> <br />
        </>
      )
    }
  };

  const transactionBreakdown = txBreakdownOptions[transactionType] || txBreakdownOptions.common;

  const { state, updateTransactionStatusModalState, handleProcessingLoader } = useTransactionStatus();

  useEffect(() => {
    if (state.clickedTransactionIds?.length) {
      refetchTransaction();
    }
  }, [state.clickedTransactionIds]);

  const payoutDetails = () => ({
    status: (
      <>
        <span
          className={`status-pill smaller ${
            status === 'requires_auth' ? 'yellow' : status === 'paused' ? 'dark-grey' : switchStatus(status)
          }`}
          style={{ margin: '0 7px 5px 0' }}
        />
        {capitalize(
          status === 'requires_auth' ? 'Pending (Requires Authorization)' : status === 'paused' ? 'On hold' : capitalizeRemovedash(status) || 'Not Available'
        )}
        {status === 'processing' &&
          ['mobile_money', 'bank_account'].includes(data?.payment_destination_type || data?.payment_source_type) &&
          !state.clickedTransactionIds?.includes(processorReference) &&
          isAllowed(userAccess, ['payouts_status.update']) && (
            <button
              onClick={e => {
                e.stopPropagation();
                updateTransactionStatusModalState(true, data);
              }}
              type="button"
              className="status-update-icon"
            >
              <Icon name="settings" />
            </button>
          )}
        {state.clickedTransactionIds?.includes(processorReference) && status === 'processing' && (
          <ToolTip
            message={
              <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
            }
            centered
          >
            <span className="rotate">
              <Icon name="loading" />
            </span>
          </ToolTip>
        )}
      </>
    ),
    ...(['expired', 'failed'].includes(status) ? { [`reason_for_${status === 'expired' ? 'expiry' : 'failure'}`]: message } : {}),
    amount_paid: `${formatAmount(amountCharged)} ${currency}`,
    payment_method: payoutMethodMapping[destination?.type] || 'Not Available',
    ...(destination?.type === 'bank_account' && { bank_account: null }),
    ...(destination?.type === 'wallet' && { wallet: null }),
    ...(destination?.type === 'mobile_money' && { mobile_money: null }),
    recipient: capitalize(destination?.details?.account_name || payment?.customer?.name || 'Not Available'),
    email: payment?.customer?.email || 'Not provided',
    ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
  });

  const refundDetails = () => ({
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 2px 0' }}
        />
        {capitalize(status === 'requires_auth' ? 'Processing (Requires Authorization)' : capitalizeRemovedash(status) || 'Not Available')}
      </>
    ),
    refund_amount: amount,
    currency_charged: switchCurrency[currency],
    channel: `${capitalizeRemovedash(channel) || 'Not Available'}`,
    refund_destination: `${capitalize(destination) || 'Not Available'}`,
    date_created: created_at ? `${getDate(created_at)}, ${getTime(created_at)}` : 'Not available',
    date_completed: completedAt ? `${getDate(completedAt)}, ${getTime(completedAt)}` : 'Not available',
    processor: `${capitalizeRemovedash(payment_source?.processor) || 'Not available'}`,
    processor_reference: `${payment_source?.processor_reference || 'Not available'}`,
    reason_for_refund: `${capitalizeRemovedash(reversal_reason) || 'Not Available'}`
  });

  const payInDetails = () => ({
    ...(!['chargebacks', 'refunds'].includes(transactionType)
      ? {
          status: (
            <>
              <span
                className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
                style={{ margin: '0 7px 5px 0' }}
              />
              {capitalize(
                status === 'requires_auth' ? 'Pending (Requires Authorization)' : capitalizeRemovedash(status) || 'Not Available'
              )}
              {['processing', 'expired'].includes(status) &&
                ['mobile_money', 'bank_account'].includes(data?.payment_destination_type || data?.payment_source_type) &&
                !state.clickedTransactionIds?.includes(processorReference) &&
                isAllowed(userAccess, ['payins_status.update']) && (
                  <button
                    onClick={e => {
                      e.stopPropagation();
                      updateTransactionStatusModalState(true, data);
                    }}
                    type="button"
                    className="status-update-icon"
                  >
                    <Icon name="settings" />
                  </button>
                )}
              {state.clickedTransactionIds?.includes(processorReference) && ['processing', 'expired'].includes(status) && (
                <ToolTip
                  message={
                    <p>A status update request has been made on this transaction. Please refresh after a few minutes to see new status.</p>
                  }
                  centered
                >
                  <span className="rotate">
                    <Icon name="loading" />
                  </span>
                </ToolTip>
              )}
            </>
          )
        }
      : {}),
    ...(['expired', 'failed'].includes(status) && (status_reason || message) && !['refunds'].includes(transactionType)
      ? { [`reason_for_${status === 'expired' ? 'expiry' : 'failure'}`]: status_reason || message }
      : {}),
    // ...(status === 'success' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {}),
    ...(!['chargebacks', 'refunds'].includes(transactionType)
      ? {
          amount_paid: `${
            transactionType === 'refunds'
              ? formatAmount(payment_source?.amount || payment_source?.amount_collected)
              : paymentSourceType === 'bank_transfer'
              ? formatAmount(amountCollected)
              : formatAmount(amountCharged)
          } ${currency}`
        }
      : {}),
    ...(paymentSourceType === 'bank_transfer' ? { amount_expected: `${formatAmount(amountCharged)} ${currency}` } : {}),
    payment_method: paymentSourceType === 'payment_reversal' ? 'Payment Reversal' : payinMethodMapping[source?.type] || 'Not Available',
    ...(['bank_transfer', 'pay_with_bank'].includes(source?.type) && {
      bank: (
        <>
          <img
            id="bank-icon"
            src={getBankImageByName(source?.details?.bank_slug)}
            alt="bank icon"
            style={{ marginLeft: source?.details?.bank_name ? 0 : '' }}
          />
          <span style={{ display: 'inline', marginLeft: 8, color: '#414f5f' }}>
            {capitalize(source?.details?.bank_name || 'No Bank Information Available')}
          </span>
        </>
      )
    }),
    ...(source?.type === 'wallet' && { wallet: null }),
    ...(source?.type === 'card' && { card: null }),
    ...(source?.type === 'mobile_money' && { mobile_money: null }),
    ...(['bank_transfer', 'pay_with_bank', 'wallet'].includes(source?.type) && {
      account_number: source?.details?.account_number || 'Not Available'
    }),
    account_name: (
      <>
        {source?.type === 'bank_transfer' && <span>{capitalize(source?.details?.account_name || 'Not Available')}</span>}
        {((['card', 'wallet', 'pay_with_bank', 'mobile_money', 'payment_reversal'].includes(source?.type)) ||
          paymentSourceType === 'payment_reversal') && <span>{capitalize(payment?.customer?.name || 'Not Available')}</span>}
      </>
    ),
    email: (['chargebacks'].includes(transactionType) ? merchant_email : payment?.customer?.email) || 'Not provided',
    ...(['refunds'].includes(transactionType) && metadata?.trace_id ? { trace_id: metadata?.trace_id } : {})
    // ...(status === 'success' && transactionType === 'refunds' ? { Reason_for_refund: reversal_reason || 'Not Available' } : {})
  });

  const chargebackDetails = () => ({
    status: (
      <>
        <span
          className={`status-pill smaller ${status === 'requires_auth' ? 'yellow' : switchStatus(status)}`}
          style={{ margin: '0 7px 2px 0' }}
        />
        <span style={{ margin: '0' }}>{capitalizeRemovedash(status) || 'Not Available'}</span>
      </>
    ),
    currency_charged: currency,
    channel: payinMethodMapping[source?.type] || 'Not Available',
    date_contested: `${getDate(created_at)}, ${getTime(created_at)}`,
    date_completed: `${capitalize(payment_source?.channel || 'Not available')}`,
    processor: `${capitalizeRemovedash(processor) || 'Not available'}`,
    reason_for_chargeback: capitalize(reason) || 'Not Available',
    deadline: `${getDate(deadline)}, ${getTime(deadline)}`
  });

  const cardTransactionDetails = () => ({
    "cardholder's_name": `${card?.card_holder?.first_name} ${card?.card_holder?.last_name}`,
    PAN: card?.last_four ? (
      <>
        <img src={switchCard[card?.brand]} className="card-logo" alt="card-logo" style={{ width: '1.5rem', marginRight: '8px' }} />
        <Copyable text={card?.last_four} spanClassName="semibold" textModifier={text => `**** **** **** ${text}`} />
      </>
    ) : (
      'N/A'
    ),
    Card_ID: <Copyable text={card?.reference} textModifier={text => truncateString(text, 15)} />,
    expiry_date: card?.expiration_date ? specialisedDate(card?.expiration_date, 'MM/YY') : 'N/A'
  });

  const additionalInfoOptions = type => {
    switch (type) {
      case 'payouts':
        return {
          title: 'Recipient Information',
          data: payoutDetails()
        };
      case 'pay-ins':
        return {
          title: "Payer's Information",
          data: payInDetails()
        };
      case 'virtual-card':
        return {
          title: "Cardholder's Information",
          data: cardTransactionDetails()
        };
      case 'refunds':
        return {
          title: 'Refund Details',
          data: refundDetails()
        };
      case 'chargebacks':
        return {
          title: 'Chargeback Details',
          data: chargebackDetails()
        };
      case 'chargebacks-payins':
        return {
          title: "Payer's Information",
          data: payInDetails()
        };
      default:
        return null;
    }
  };

  const hasPayoutReversal = !!payoutReversal?.id;
  const allActionButtonOptions = {
    payoutReversal: {
      label: hasPayoutReversal ? 'Reversal initiated' : 'Reverse Payout',
      onClick: () => setShowReversalInitiateModal(true),
      disabled: hasPayoutReversal,
      iconRight: <i className="os-icon os-icon-corner-up-left" />,
      color: 'light-blue'
    },
    manageRefunds: {
      label: 'Manage Refund',
      onClick: () => setManageRefundsModalOpen(true),
      iconLeft: <i className="os-icon os-icon-settings" />
    },
    chargebacks: {
      label: 'View History',
      onClick: () => setChargebackModalState({ type: 'feedback', open: true }),
      disabled: transactionType !== 'chargebacks',
      color: 'primary'
    },
    managePausedPayments: {
      buttons: [
        {
          label: 'Cancel',
          color: 'light-blue',
          onClick: () => setManagePausedPaymentsModal('cancel_single')
        },
        {
          label: 'Process',
          color: 'primary',
          onClick: () => setManagePausedPaymentsModal('process_single')
        }
      ]
    }
  };

  const pausedActionButtons = [
    ...(status === 'paused' && Array.isArray(allActionButtonOptions.managePausedPayments?.buttons)
      ? allActionButtonOptions.managePausedPayments.buttons
      : [])
  ];

  const payoutActionButtons = [
    ...(transactionType === 'payouts' && hasPayoutReversalAccess && status === 'success' ? [allActionButtonOptions.payoutReversal] : [])
  ];

  const chargebackButtons = [
    ...(transactionType === 'chargebacks' && status_history && status_history.length > 0 ? [allActionButtonOptions.chargebacks] : [])
  ];

  const refundsActionButtons = [
    ...(transactionType === 'refunds' && hasRefundsUpdateAccess && ['failed', 'manual'].includes(status)
      ? [allActionButtonOptions.manageRefunds]
      : [])
  ];

  const actionButtons = [...payoutActionButtons, ...refundsActionButtons, ...chargebackButtons, ...pausedActionButtons];

  return (
    <>
      <div className="content-i txn-detail">
        <div className="content-box">
          <div className="header-row">
            <div className="col-sm-12 pl-0 pr-0">
              {!isLoading && (
                <button type="button" className="btn btn-link" onClick={() => history.goBack()}>
                  <i className="os-icon os-icon-arrow-left7" />
                  <span>Go Back</span>
                </button>
              )}
              {!isLoading && (
                <section className="invoice-heading">
                  <div className="invoice-info">
                    <h3 className="amount-heading">
                      {['refunds'].includes(transactionType) ? (
                        id?.toUpperCase?.()
                      ) : (
                        <>
                          {formatAmount(amountCollected || amount || converted_amount || '0.00')} <span>{[currency, destination_currency]}</span>
                        </>
                      )}
                    </h3>
                    <div className="invoice-date">
                      {!['chargebacks'].includes(transactionType) && '|'}
                      {transactionType !== 'currency-exchange' && (
                        <span
                          style={{
                            color: switchTrxnMessage[status]?.color,
                            backgroundColor: switchTrxnMessage[status]?.backgroundColor
                          }}
                        >
                          {switchTrxnMessage[status]?.name || switchTrxnMessage?.processing?.name}
                        </span>
                      )}
                      {transactionType === 'currency-exchange' && (
                        <span
                          style={{
                            color: switchTrxnMessage[swapStatus]?.color,
                            backgroundColor: switchTrxnMessage[swapStatus]?.backgroundColor
                          }}
                        >
                          {switchTrxnMessage[swapStatus]?.name || switchTrxnMessage?.processing?.name}
                        </span>
                      )}
                    </div>
                  </div>

                  {!['chargebacks', 'refunds', 'payouts', 'pay-ins', 'virtual-card', 'currency-exchange'].includes(transactionType) && (
                    <div id="update-refund">
                      <p className="amount-heading --refund">
                        {formatAmount(amount || '0.00')}
                        <span>{[currency]} </span>
                      </p>
                    </div>
                  )}
                  {actionButtons.length > 0 && (
                    <div className="action-btns-container">
                      {actionButtons.map(({ label, onClick, iconLeft = null, iconRight = null, color, ...btnProps }) => (
                        <button key={label} className={`btn btn-${color || 'primary'}`} type="button" onClick={onClick} {...btnProps}>
                          {iconLeft && <span className="mr-2">{iconLeft}</span>}
                          {label}
                          {iconRight && <span className="ml-2">{iconRight}</span>}
                        </button>
                      ))}
                    </div>
                  )}
                </section>
              )}
            </div>
          </div>
          <div className=" elemenet-box transaction-details-container mt-5">
            <section className="trxn-information">
              {isLoading ? (
                <LoadingPlaceholder type="text" content={4} />
              ) : (
                <article>
                  <ul className="trxn-breakdown-list">
                    {Object.keys(transactionBreakdown).map(item => {
                      if (!['refunds'].includes(transactionType) && (!transactionBreakdown[item] || transactionBreakdown[item] === ' '))
                        return null;
                      if (item !== 'reason_for')
                        return (
                          <li key={Math.floor(1000 + Math.random() * 900)}>
                            <p>
                              {item === 'transaction_id' && 'Transaction ID'}
                              {item === 'settlement_id' && 'Settlement ID'}
                              {!['transaction_id', 'settlement_id'].includes(item) && capitalizeRemovedash(item)}
                              {item === 'transaction_fee' && '(VAT Inclusive)'}
                              {item === 'net' && <ToolTip type="net_amount" image={InfoIcon} message={<>This is the amount less fees</>} />}
                            </p>
                            <p style={{ maxWidth: '350px' }}>{transactionBreakdown[item]}</p>
                          </li>
                        );
                    })}
                  </ul>
                </article>
              )}
            </section>
          </div>
          <div className="transaction-details__comp_2">
            <div className="transaction-details-container-2">
              <section className="customer-information">
                {isLoading ? (
                  <LoadingPlaceholder type="text" content={4} />
                ) : (
                  <article>
                    <ul>
                      {/* ===========Transaction Type Specific Details=========== */}
                      <li>
                        <p className="section-heading" id="section-title" hidden={!additionalInfoOptions(transactionType)?.title}>
                          {additionalInfoOptions(transactionType)?.title}

                          <button
                            onClick={() => setTrxBreakdownModalOpen(true)}
                            className="view-breakdown"
                            type="button"
                            style={{ marginLeft: 'auto' }}
                          >
                            View Breakdown <Icon name="arrowUpRight" style={{ marginLeft: 6 }} />
                          </button>
                        </p>
                        {Object.keys(additionalInfoOptions(transactionType)?.data || {}).map(item => (
                          <p key={item}>
                            {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                              paymentChannels(item)
                            ) : (
                              <>
                                <span>{capitalizeRemovedash(item)}</span>
                                <span>{additionalInfoOptions(transactionType)?.data?.[item]}</span>
                              </>
                            )}
                          </p>
                        ))}
                      </li>
                      {['chargebacks', 'refunds'].includes(transactionType) && (
                        <li>
                          <p className="section-heading" id="section-title" hidden={!additionalInfoOptions('chargebacks-payins')?.title}>
                            {additionalInfoOptions('chargebacks-payins')?.title}
                          </p>
                          {Object.keys(additionalInfoOptions('chargebacks-payins')?.data || {}).map(item => (
                            <p key={item}>
                              {['bank_account', 'card', 'wallet', 'mobile_money'].includes(item) ? (
                                paymentChannels(item)
                              ) : (
                                <>
                                  <span>{capitalizeRemovedash(item)}</span>
                                  <span>{additionalInfoOptions('chargebacks-payins')?.data?.[item]}</span>
                                </>
                              )}
                            </p>
                          ))}
                        </li>
                      )}
                      {/* ===========Customer's Virtual Account Details=========== */}
                      {transactionType === 'pay-ins' && source?.virtual_bank_account && <li>{virtualAccount()}</li>}

                      {/* ===========More Transaction Details=========== */}
                      {!['chargebacks', 'refunds'].includes(transactionType) && (
                        <li>
                          <p
                            className="section-heading more-trxn-heading"
                            style={{
                              marginTop: '60px',
                              marginBottom: '20px'
                            }}
                          >
                            More Transaction Details
                          </p>
                          {Object.keys(morePaymentDetails).map(item => (
                            <p>
                              <span>
                                {item === 'rnn' && (
                                  <>
                                    RNN <ToolTip image={InfoIcon} message="Reference Retrieval Number" />
                                  </>
                                )}
                                {item === 'stan' && (
                                  <>
                                    STAN <ToolTip image={InfoIcon} message="System Trace Audit Number" />
                                  </>
                                )}
                                {item === 'cross-currency' && (
                                  <>
                                    Cross-Currency Status{' '}
                                    <ToolTip
                                      image={InfoIcon}
                                      message="This defines whether a transaction is performed in the issued virtual card currency or not"
                                    />
                                  </>
                                )}
                                {!['stan', 'rnn', 'cross-currency'].includes(item) && capitalizeRemovedash(item)}
                                {item === 'approval_code' && <ToolTip image={InfoIcon} message="Also known as ‘Acquirer code" />}
                              </span>
                              <span>{morePaymentDetails[item]}</span>
                            </p>
                          ))}
                        </li>
                      )}

                      {/* ===========Business Information (Virtual Cards)=========== */}
                      {transactionType === 'virtual-card' && (
                        <li>
                          <p className="section-heading mt-5" id="section-title">
                            Business Information
                          </p>
                          {Object.keys(virtualCardsBusinessInformation).map(item => (
                            <p key={item}>
                              <span>{capitalizeRemovedash(item)}</span>
                              <span>{virtualCardsBusinessInformation?.[item]}</span>
                            </p>
                          ))}
                        </li>
                      )}

                      {/* ============= Reversal, Refunds & Chargeback Tabs ============ */}
                      {(transactionType === 'pay-ins' || transactionType === 'payouts') && ReversalTabs()}

                      {/* ===========Chargeback Activity Update=========== */}
                      {['chargebacks'].includes(transactionType) && (
                        <li>
                          <ChargebackActivityUpdate
                            resolveAction={() =>
                              setChargebackModalState({
                                open: true,
                                type: 'resolve-chargeback'
                              })
                            }
                            data={data}
                          />
                        </li>
                      )}
                    </ul>
                  </article>
                )}
              </section>
            </div>
          </div>
        </div>
        <ReversalsInfoModal close={() => setModalOpen(false)} visible={modalOpen} />
        {chargebackModalState.open && (
          <ChargebackDetailsModal
            close={() => setChargebackModalState({ open: false, type: '' })}
            type={chargebackModalState.type}
            data={status_history}
            reference={reference}
            setModalState={value => setChargebackModalState({ open: true, type: value })}
          />
        )}
        {showReversalInitiateModal && (
          <ReversePayoutModal
            transaction={data}
            onReversalInitiated={refetchTransaction}
            close={() => setShowReversalInitiateModal(false)}
          />
        )}
        {manageRefundsModalOpen && (
          <ManageRefundsModal
            close={() => setManageRefundsModalOpen(false)}
            visible={manageRefundsModalOpen}
            referenceId={id}
            currentStatus={status}
          />
        )}
        {managePausedPaymentsModal && (
          <PausedPaymentsModal
            size="sm"
            heading={modalContent.heading || 'Action required to proceed'}
            close={() => setManagePausedPaymentsModal('')}
            visible={!!managePausedPaymentsModal}
            secondButtonText={modalContent.secondButtonText || 'Confirm'}
            secondButtonColor={modalContent?.secondButtonColor}
            secondButtonAction={mutateAsync}
            content={<PausedPaymentsModalContent refs={[id]} stage={managePausedPaymentsModal} />}
            completedDescription={modalContent.completedDescription || 'Successfully initiated'}
            completedActionText="Dismiss"
            description={undefined}
            secondaryCompletedModal
            completedImage={modalContent.completedImage}
            completedHeading={modalContent.completedHeading || 'Success'}
          />
        )}
        {state.openTransactionStatusModal && (
          <TransactionStatusModal
            activeTransaction={state.activeTransaction}
            updateModalState={updateTransactionStatusModalState}
            triggerProcessingLoader={handleProcessingLoader}
            transactionType={transactionType === 'pay-ins' ? 'payins' : 'payout'}
          />
        )}
        {trxBreakdownModalOpen && (
          <TrxBreakdownModal
            trx={{
              currency,
              amount_charged: formatAmount(amountCharged),
              amount_paid: formatAmount(amount),
              fees: formatAmount(+fee + (+vat || 0)),
              ...(meta?.additional_fees?.stamp_duty && { stamp_duty_fee: meta?.additional_fees?.stamp_duty }),
              ...(payment?.sentinal_transaction
                ? {
                    tax: formatAmount(Number(payment.sentinal_transaction.vat) + Number(payment.sentinal_transaction.processing_fee)),
                    net_amount: formatAmount(
                      +netAmount - Number(payment.sentinal_transaction.vat) - Number(payment.sentinal_transaction.processing_fee)
                    )
                  }
                : { net_amount: netAmount })
            }}
            close={() => setTrxBreakdownModalOpen(false)}
          />
        )}
      </div>
      <section className="back-to-top">{ScrollToTopSection()}</section>
    </>
  );
}

export default TransactionDetails;
