/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import Modal from '+shared/Modal';
import EmptyStateComponent from '+shared/EmptyState';
import CurrencyPicker from '+shared/CurrencyPicker';
import APIRequest from '+services/api-services';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import { storageDataKey } from '+utils';
import info from '+assets/img/dashboard/info.svg';

import './index.scss';

const formatSettings = {
  accept: 'Accept',
  return_excess: 'Return Excess',
  return_all: 'Return All'
};

const api = new APIRequest();
export default function PaymentPreference() {
  const queryCache = useQueryClient();
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();
  const [activeCurrency, setActiveCurrency] = useState('NGN');
  const availableCurrencies = queryCache.getQueryData(storageDataKey.AVAILABLE_CURRENCIES);
  const [showModal, setShowModal] = useState({
    information: false
  });
  const [modalstate, setModalState] = useState('');
  const errorDispatch = message =>
    feedbackInit({
      message,
      type: 'danger'
    });

  const { data: paymentPref } = useQuery(`${id}_MERCHANT_PAYMENT_PREFERENCE`, () => api.getMerchantPaymentPreference(id), {
    onError: () => errorDispatch(`There has been an error fetching merchant payment preference.`)
  });

  const { data: currencyMetadata } = useQuery(`MERCHANT_CURRENCY_DATA`, () => api.getCurrencies(), {
    onError: () => errorDispatch(`There has been an error fetching merchant payment preference.`)
  });

  const settings = paymentPref?.settings?.payment_events;
  const lastUpdated = settings?.last_updated_at;
  const date = new Date(lastUpdated);
  const isBankTransferAvailableOnCurrency = currency => {
    return currencyMetadata?.reduce((returnValue, element) => {
      if (currency === element.code) {
        const findBankTransfer = element?.meta_data?.payment_types?.payin.find(item => item.value === 'bank_transfer');
        if (findBankTransfer) {
          // eslint-disable-next-line no-param-reassign
          returnValue = true;
        }
      }
      return returnValue;
    }, false);
  };
  const modalContent = () => {
    switch (modalstate) {
      case 'Underpayments':
        return (
          <section className="payment-preferences__modal-content">
            <article className="mb-4">
              <p>Underpayment occurs when a customer is expected to pay a fixed amount but pays less than the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Accept All</h6>
              <p>This means you would receive any paid below the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Return All</h6>
              <p>We would return the amount paid by the customer instantly.</p>
            </article>
          </section>
        );
      case 'Overpayments':
        return (
          <section className="payment-preferences__modal-content">
            <article className="mb-4">
              <p>Over-payment occurs when a customer is expected to pay a fixed amount but pays more than the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Accept All</h6>
              <p>This means you would receive any paid below the expected amount.</p>
            </article>
            <article className="my-4">
              <h6>Return All</h6>
              <p>We would return the amount paid by the customer instantly.</p>
            </article>
            <article className="my-4">
              <h6>Return Excess</h6>
              <p>We would receive the amount expected but return the excess amount</p>
            </article>
          </section>
        );
      default:
        return <div />;
    }
  };
  return (
    <section className="payment-preference">
      <div className="element-box">
        <h4 className="form-header">Payment Preferences</h4>
        <div className="form-desc no-underline">You can view merchant's payment preferences here. </div>
        <div className="form-desc">
          <CurrencyPicker
            options={availableCurrencies}
            onChange={value => {
              setActiveCurrency(value);
            }}
            activeCurrency={activeCurrency}
            label={<span className="payment-preferences__config">Show configuration for:</span>}
            id="balances__currency-switch"
          />
        </div>
        <div className="payment-preferences__heading-sub">
          <div>
            <h5 className="form-header pt-0">Payment Preferences by Payment Methods</h5>
            <div className="form-desc no-underline mb-1">This is the preference applied to this merchant for each payment method</div>
          </div>
          {lastUpdated && (
            <div className="payment-preferences__last-updated">
              <p>Last updated: </p>
              <p>{date.toDateString()}</p>
            </div>
          )}
        </div>
        <div className="os-tabs-controls os-tabs-complex mx-0">
          <ul className="nav nav-tabs px-0 mb-2">
            <li className="nav-item">
              <button type="button" className="nav-link active" data-toggle="tab">
                <span className="tab-label">Bank Transfer</span>
              </button>
            </li>
          </ul>
        </div>
        {isBankTransferAvailableOnCurrency(activeCurrency) ? (
          <div className="payment-preferences__dest-w grey-section">
            <div className="grey-section__subsection payment-preferences__section">
              <div className="grey-section__subsection-group">
                <div className="grey-section__subsection-item">
                  <div>
                    <h6 className="grey-section__title">
                      Overpayments
                      <button
                        onClick={() => {
                          setModalState('Overpayments');
                          setShowModal(prev => {
                            return { ...prev, information: true };
                          });
                        }}
                        type="button"
                        className="ml-2 infobutton"
                      >
                        <img src={info} alt="more information" aria-hidden />
                      </button>
                    </h6>

                    <p>Merchant’s preference for when a customer makes an overpayment.</p>
                  </div>
                  <div className="form-group mb-0">
                    <p className="form-control  payment-preferences__pref">{formatSettings[(settings?.overpayment?.action)]}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="grey-section__subsection payment-preferences__section">
              <div className="grey-section__subsection-group">
                <div className="grey-section__subsection-item">
                  <div>
                    <h6 className="grey-section__title">
                      Underpayments
                      <button
                        onClick={() => {
                          setModalState('Underpayments');
                          setShowModal(prev => {
                            return { ...prev, information: true };
                          });
                        }}
                        type="button"
                        className="ml-2 infobutton"
                      >
                        <img src={info} alt="more information" aria-hidden />
                      </button>
                    </h6>
                    <p>Merchant’s preference for when a customer makes an underpayment.</p>
                  </div>
                  <div className="form-group mb-0">
                    <p className="form-control payment-preferences__pref">{formatSettings[(settings?.underpayment?.action)]}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <EmptyStateComponent />
        )}
      </div>
      <Modal
        size="md"
        close={() =>
          setShowModal(prev => {
            return { ...prev, information: false };
          })
        }
        visible={showModal.information}
        heading={modalstate}
        content={modalContent()}
        showButtons={false}
      />
    </section>
  );
}
