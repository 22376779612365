/* eslint-disable camelcase */
import React from 'react';
import EmptyState from "+shared/EmptyState";
import { capitalize, capitalizeRemovedash, formatAmount, getDate, getTime, history, switchCurrency, switchStatus, isAllowed } from "+utils";
import {useSetUserAccess} from '+hooks';
import {chargebackTableHistoryDataT} from "+types";



type ChargebackTableHistoryType = {
  activeList: {
    data: chargebackTableHistoryDataT[]
  },
  activeTab: string
  activeCurrency: keyof typeof switchCurrency
}
const ChargebackTableHistory = ({ activeList, activeTab, activeCurrency }: ChargebackTableHistoryType) => {
     const userAccess = useSetUserAccess();

  const goToChargebackDetails = (reference:string) => {
    if (isAllowed(userAccess, ['chargeback_details.view'])) {
      history.push(`/dashboard/disputes/chargebacks/${reference}`,
        {
          type: 'chargeback'
        })
    } else {
      history.push('/dashboard/access-denied')
    }
  }

    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          {activeList && activeList.data?.length !== 0 ? (
            <>
              <div>
                {activeList?.data?.map(chargeback => {
                  return (
                    <div
                      key={`reversal_${chargeback.reference}`}
                      className="div-table --chargeback-table --row"
                      role="button"
                      tabIndex={0}
                      onClick={() => goToChargebackDetails(chargeback.reference)}
                      onKeyDown={() => goToChargebackDetails(chargeback.reference)}
                    >
                      <div style={{ display: 'flex' }}>
                        <span className="body-row-header">Status:</span>
                        <span className={`status-pill smaller ${switchStatus(chargeback.status)}`} style={{ marginTop: '6px' }} />
                        <span>{chargeback.status === 'auto_accepted' ? 'Accepted (Auto)' :capitalizeRemovedash(chargeback.status || 'N/A')}</span>
                      </div>
                      <div>
                        <span className="body-row-header">{`${capitalize(activeTab)} ID`}:</span>
                        <span
                          className="trxn-id"
                          style={{ color: '#414F5F', fontWeight:600 }}
                          role="button"
                          tabIndex={0}
                        >
                          {chargeback?.reference?.toUpperCase() || 'Not available'}
                        </span>
                      </div>
                      <div>
                        <span className="body-row-header">Transaction ID:</span>
                        <span
                          className="trxn-id"
                          style={{ color: '#414F5F', fontWeight:600 }}
                          role="button"
                          tabIndex={0}
                        >
                          {chargeback?.payment_reference?.toUpperCase() || 'Not available'}
                        </span>
                      </div>
                      <div>
                        <span className="body-row-header">Merchant:</span>
                        <span className="">{capitalize(chargeback?.merchant || 'Not available')}</span>
                      </div>
                      <div>
                        <span className="body-row-header">Channel:</span>
                        <span>
                          { chargeback.payment_method
                            ? capitalize(
                                chargeback.payment_method === 'card'
                                  ? `${chargeback.payment_method} Payment`
                                  : capitalizeRemovedash(chargeback.payment_method)
                              )
                            : 'Not available'}
                        </span>
                      </div>
                      <div>
                        <span className="body-row-header">Date / Time:</span>
                        <span>{getDate(chargeback?.created_at)}</span>
                        <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                          {getTime(chargeback?.created_at)}
                        </span>
                      </div>
                      <div>
                        <span className="body-row-header">{`${capitalize(activeTab === "chargeback" ? "dispute": activeTab)} Amount `}{ activeCurrency }</span>
                        <span style={{ fontWeight: '500' }}>
                          {formatAmount(chargeback?.amount)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          ) : (
            <EmptyState />
          )}
        </div>
      </div>
    );
  };

  export default ChargebackTableHistory
