import React, { useState, useReducer, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import {
  history,
  lowercaseRemovedash,
  capitalizeRemovedash,
  logError,
  switchStatus,
  formatAmount,
  capitalize,
  getDate,
  getTime,
  truncateString,
  APIDownload,
  isNullish,
  filteredOutObjectProperty,
  renameObjectKeys,
  isAllowed,
  queriesParams
} from '+utils';
import APIRequest from '+services/api-services';
import useStore from '+zustandStore';

import Copyable from '+shared/Copyable';
import Table from '+shared/Table';
import Modal from '+shared/Modal';
import LargeExportModal from '+shared/LargeExportModal';
import SecondaryDetails from '+shared/SecondaryDetails';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import CardThumbnail from './components/CardThumbnail';
import CardUpdate from './components/CardStatusControlModal';

import cardIcon from '+assets/img/dashboard/card-icon.svg';
import lock from '+assets/img/dashboard/lock.svg';
import mastercard from '+assets/img/logos/mastercard.svg';
import visa from '+assets/img/logos/visa.svg';

import { switchTabData, switchInfoDescription } from '../data';
import './index.scss';

const api = new APIRequest();

type detailTabs = 'transactions' | 'balance_history' | 'events';
type summaryElement = Record<string, string | JSX.Element>;
type CardUpdateInfo = {
  action: string;
  reason: string;
  initiator: string;
};
type actionTypes = 'suspend' | 'activate' | 'terminate';
type CardMgmtOptions = Record<actionTypes, (info: CardUpdateInfo) => Promise<unknown>>;

export default function IssuedCardDetails() {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation<{ pathname: string }>();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const { profile } = useStore((state) => state);
  const transactionType = pathname.split('/').slice(-2)[0];
  const isReservedCard = transactionType === 'reservedCards';
  const queryClient = useQueryClient();
  const userAccess = useSetUserAccess();

  // eslint-disable-next-line @typescript-eslint/ban-types
  const tabs: Record<detailTabs, Function> = {
    transactions: api.fetchVirtualCardTransactions,
    balance_history: api.fetchVirtualCardTransactions,
    events: api.fetchVirtualCardEvents
  };

  const [showMore, setShowMore] = useState(false);
  const [showLargeExportModal, setLargeExportModal] = useState<boolean>(false);

  const activeTab = searchQuery.value.tab || 'transactions';
  const page = searchQuery.value.page || 1;
  const limit = searchQuery.value.limit || 10;

  const status = searchQuery.value.status || [];
  const filteredSortingParams = filteredOutObjectProperty(searchQuery.value, [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.sorterType,
    queriesParams.status
  ]);

  const paramsToRename =
    activeTab === 'transactions'
      ? {
          dateFrom: 'dateCreatedFrom',
          dateTo: 'dateCreatedTo'
        }
      : {};

  const sortingParams = {
    status: typeof status === 'string' ? [status] : status,
    ...renameObjectKeys(filteredSortingParams, paramsToRename)
  };

  const computeViewPermissions = () => {
    if (activeTab === 'transactions' && !isAllowed(userAccess, ['card_issuance_transactions.view'])) return false;
    if (activeTab === 'balance_history' && !isAllowed(userAccess, ['card_issuance_wallet_history.view'])) return false;
    if (activeTab === 'events' && !isAllowed(userAccess, ['card_issuance_card_events.view'])) return false;
    return true;
  };

  const computeExportPermissions = () => {
    if (activeTab === 'transactions' && !isAllowed(userAccess, ['card_issuance_transactions.export'])) return false;
    if (activeTab === 'balance_history' && !isAllowed(userAccess, ['card_issuance_wallet_history.export'])) return false;
    if (activeTab === 'events' && !isAllowed(userAccess, ['card_issuance_card_events.export'])) return false;
    return true;
  };

  const [state, setState] = useReducer(
    (prev: Record<string, any>, next: Record<string, any>) => {
      return { ...prev, ...next };
    },
    {
      tableData: {},
      isFilterVisible: false,
      sorterType: null,
      manageCard: false,
      showUpdateModal: false,
      showInfoModal: false,
      actionType: ''
    }
  );

  const {
    data: cardData,
    isLoading,
    refetch: refetchCard
  } = useQuery(`VIRTUAL_CARD_${id.toUpperCase()}`, () => api.fetchVirtualCard(id), {
    onError: (e: any) => {
      feedbackInit({
        message: "There has been an error getting this card's information",
        type: 'danger'
      });
    }
  });

  const {
    data: tableInfo,
    isFetching,
    refetch
  } = useQuery(
    [`${id.toUpperCase()}_${activeTab.toUpperCase()}`, page, limit, sortingParams],
    () => tabs[activeTab](id, page, limit, sortingParams),
    {
      keepPreviousData: true,
      onError: () => {
        feedbackInit({
          message: `There has been an error this card's ${lowercaseRemovedash(activeTab)}`,
          type: 'danger'
        });
      },
      enabled: computeViewPermissions()
    }
  );

  const updateCard = useMutation(({ info }) => api.updateVirtualCard(id, info), {
    retry: false,
    onSuccess: (res) => {
      successfulHandler('update', `Successfully ${cardData.status !== 'active' ? 'activated' : 'suspended'} this card`);
      refetchCard();
      queryClient.invalidateQueries([isReservedCard ? 'RESERVED_CARDS' : 'CUSTOMER_CARDS']);
    },
    onError: (e: AxiosError) => errorHandler(e, 'update')
  });

  const terminateCard = useMutation(({ info }) => api.terminateVirtualCard(id, info), {
    retry: false,
    onSuccess: (res) => {
      successfulHandler('terminate', 'Successfully terminated this card');
      refetchCard();
      queryClient.invalidateQueries([isReservedCard ? 'RESERVED_CARDS' : 'CUSTOMER_CARDS']);
    },
    onError: (e: AxiosError) => errorHandler(e, 'terminate')
  });

  const successfulHandler = (type: string, message: string): void => {
    refetchCard();
    feedbackInit({
      title: type === 'update' ? 'Update Card Status:' : 'Terminate Card:',
      message,
      type: 'success'
    });
  };

  const errorHandler = (error: AxiosError, type: string) => {
    logError(error);
    feedbackInit({
      title: type === 'update' ? 'Update Card Status:' : 'Terminate Card:',
      message: error.response?.data?.message,
      type: 'danger'
    });
    throw error;
  };

  useEffect(() => {
    setState({ tableData: switchTabData(activeTab, state, tableInfo?.paging) });
  }, [activeTab, tableInfo]);

  const summaryInfo: summaryElement = {
    Status: (
      <>
        <span className={`status-pill smaller align-baseline mr-2 ${switchStatus(cardData?.status)}`} />
        {capitalize(cardData?.status)}
      </>
    ),
    'Cardholder Name': `${cardData?.card_holder?.first_name} ${
      cardData?.card_holder?.first_name !== cardData?.card_holder?.last_name ? cardData?.card_holder?.last_name : ''
    }`,
    'Expires at': !isNullish(cardData?.expiry_month) ? `${cardData?.expiry_month} / ${cardData?.expiry_year}` : 'Not Available',
    'Card Scheme': !isNullish(cardData?.brand) ? (
      <>
        <img
          className="card-scheme"
          alt={cardData?.brand}
          src={cardData?.brand === 'visa' ? visa : mastercard}
          style={{ width: '25px', marginRight: '7px' }}
        />
        {cardData?.brand === 'visa' ? 'Visa Card' : 'Mastercard'}
      </>
    ) : (
      'Not Available'
    ),
    'Created at': !isNullish(cardData?.date_created)
      ? `${getDate(cardData?.date_created)} ${getTime(cardData?.date_created)}`
      : 'Not Available',
    'Unique Card ID': (
      <Copyable
        text={cardData?.reference?.toUpperCase()}
        textModifier={(text: string) => truncateString(text, 15)}
        buttonClassName="card-copy-btn"
      />
    ),
    Processor: !isNullish(cardData?.provider) ? capitalize(cardData?.provider) : 'Not Available',
    "Processor's Card ID": !isNullish(cardData?.provider_reference) ? capitalize(cardData?.provider_reference) : 'Not Available',
    'Billing Address': !isNullish(cardData?.billing)
      ? `${cardData?.billing?.address1 || ''} ${cardData?.billing?.address2 || ''}, ${cardData?.billing?.city}, ${
          cardData?.billing?.state
        }, ${cardData?.billing?.zip_code}, ${cardData?.billing?.country}`
      : 'Not Available'
  };
  const keysToShow = showMore ? Object.keys(summaryInfo) : Object.keys(summaryInfo).slice(0, 5);

  const filteredSummaryInfo = keysToShow.reduce((acc, key) => {
    acc[key] = summaryInfo[key] as React.ReactNode | string;
    return acc;
  }, {} as Record<string, React.ReactNode | string>);

  const tableDataKeys =
    Object.keys(state.tableData).length > 0 ? Object.keys(state?.tableData?.fields({ currency: cardData?.currency })?.data) : [];

  const cardManagementOptions: CardMgmtOptions = {
    [cardData?.status === 'suspended' ? 'activate' : 'suspend']: (info: CardUpdateInfo) => updateCard.mutateAsync({ info }),
    terminate: (info: CardUpdateInfo) => terminateCard.mutateAsync({ info })
  };

  const exportRecords = async (format: string, close: () => void, fieldsToExport: string[]) => {
    try {
      const res: Awaited<Blob> = await tabs[activeTab](id, page, limit, sortingParams, true, format, fieldsToExport);
      if (res.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `card-transactions_${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: <> - Successfully exported record.</>,
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your record`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  return (
    <div className="issued-cards-details__comp ">
      <div>
        <button type="button" className="btn btn-link mb-3 pl-0" onClick={() => history.goBack()}>
          <i className="os-icon os-icon-arrow-left7" />
          <span>Go back</span>
        </button>
      </div>

      <section>
        <div className="card-details-heading">
          <div className="id-area">
            <div className="avatar">
              <img src={cardIcon} alt="avatar" className="image" />
            </div>
            <div className="texts">
              <h3 className="header-text">Card ending with **** {cardData?.last_four}</h3>
              <p className="description">{cardData?.brand === 'visa' ? 'Visa Card' : 'Mastercard'}</p>
            </div>
          </div>

          {isAllowed(userAccess, ['card_issuance_card.update']) && (
            <div style={{ alignSelf: 'flex-end' }}>
              <button
                type="button"
                className="btn btn-secondary link-btn pr-0"
                hidden={['terminated', 'expired'].includes(cardData?.status)}
                onClick={() => setState({ manageCard: true })}
              >
                <span>Manage Card</span>
                <i className="os-icon os-icon-chevron-down icon" />
              </button>
            </div>
          )}

          {state.manageCard && (
            <ul className="element-box ellipsis__nav card-ellipsis">
              {Object.keys(cardManagementOptions).map((type: string) => (
                <li
                  key={type}
                  role="presentation"
                  className="ellipsis__item"
                  onClick={() =>
                    setState({
                      manageCard: false,
                      showModal: true,
                      actionType: type
                    })
                  }
                >
                  <span style={{ color: type === 'terminate' ? '#F32345' : '' }}>{capitalize(type)} Card</span>
                </li>
              ))}
            </ul>
          )}
        </div>
        <hr />

        <div className="summary-section">
          <div className="content mt-4">
            <div className="info-side">
              {isLoading ? (
                <LoadingPlaceholder type="text" background="#f5f6f6" />
              ) : (
                <SecondaryDetails title="Summary" data={[filteredSummaryInfo]} hideLine leftSpacing="0" />
              )}
            </div>

            <div className="card-side">
              <div className="mb-4 card-container">
                <CardThumbnail
                  cardDetails={{
                    status: cardData?.status || '',
                    scheme: cardData?.brand || '',
                    holderName: `${cardData?.card_holder?.first_name} ${
                      cardData?.card_holder?.first_name !== cardData?.card_holder?.last_name ? cardData?.card_holder?.last_name : ''
                    }`,
                    expiresAt: `${cardData?.expiry_month || '-'}/${cardData?.expiry_year?.slice(2) || '--'}`,
                    firstSixDigits: cardData?.first_six || '',
                    lastFourDigits: cardData?.last_four || '',
                    color: cardData?.color
                  }}
                />
              </div>
              <div className="info-container">
                <p className={`balance-value ${!['active', 'expired'].includes(cardData?.status) ? 'grey-text' : ''}`}>
                  {cardData?.status !== 'active' && <img alt="lock icon" src={lock} style={{ width: '15px', margin: '0 10px 8px 0' }} />}
                  <span className="digit">{formatAmount(cardData?.balance || 0)}</span>
                  <span className="currency pl-2">{cardData?.currency}</span>
                </p>
                <div className="balance-label">Available Balance {!['active', 'expired'].includes(cardData?.status) && '(Locked)'}</div>
              </div>
            </div>
          </div>
          {!isLoading && (
            <button type="button" className="btn btn-link center" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Less Details' : 'More Details'}
              <i className="os-icon os-icon-chevron-down icon" style={{ fontSize: '1.25rem' }} />
            </button>
          )}
        </div>

        {['suspended', 'terminated', 'expired'].includes(cardData?.status) && (
          <section
            // eslint-disable-next-line no-nested-ternary
            className={`mt-3 status-banner __${cardData.status === 'terminated' ? 'deleted' : cardData.status}`}
            style={{
              textAlign: 'center',
              color: cardData.status === 'terminated' ? 'white' : '#3e4b5b'
            }}
          >
            This card has been {cardData.status}!
            <button type="button" className="btn btn-link" onClick={() => setState({ showInfoModal: true })}>
              Learn why
            </button>
          </section>
        )}

        <section>
          <div className="os-tabs-controls os-tabs-complex mx-0 mb-0 mt-3">
            <ul className="nav nav-tabs px-0">
              {Object.keys(tabs)?.map((tab: string) => (
                <li className="nav-item" key={tab}>
                  <button
                    type="button"
                    className={tab === activeTab ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    tabIndex={0}
                    onClick={() => {
                      searchQuery.setQuery({ tab }, true);
                    }}
                    onKeyDown={() => {
                      searchQuery.setQuery({ tab }, true);
                    }}
                  >
                    {capitalizeRemovedash(tab)}
                    {tab === 'chargebacks' && <p className="badge badge-danger">{tableInfo?.data.length}</p>}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          <section className="transaction_table_comp table-container mt-3">
            <Table
              dataTestId={activeTab}
              header={null}
              className={state.tableData?.className || ''}
              tableHeadings={tableDataKeys}
              loading={isFetching}
              data={tableInfo?.data}
              renderFields
              hasPagination
              annotation={state.tableData?.annotations}
              current={parseInt(page, 10)}
              rowKey={state.tableData?.rowKey}
              rowURL={state.tableData?.rowURL}
              pageSize={tableInfo?.paging?.page_size}
              totalItems={tableInfo?.paging?.total_items}
              limitAction={(c: number) => searchQuery.setQuery({ limit: c })}
              actionFn={(c: number) => searchQuery.setQuery({ page: c })}
              emptyStateHeading={state.tableData?.emptyStateHeading || ''}
              emptyStateMessage={
                <>
                  <span>{state.tableData?.emptyStateMessage || ''}</span>
                  <button type="button" className="refetch-button" onClick={() => refetch()}>
                    <i className="os-icon os-icon-rotate-ccw" style={{ marginRight: '5px' }} />
                    Refresh
                  </button>
                </>
              }
              type={state.tableData?.type || ''}
              filterName={state.tableData?.filterTitle || ''}
              filterExportAction={exportRecords}
              filterTotalCount={tableInfo?.paging?.total_items}
              filterShowExport={computeExportPermissions()}
              filterActiveCurrency={cardData?.currency}
              filterHasBasicFilter={state.tableData?.hasBasicFilter ?? true}
              filterHasAdvancedFilter={state.tableData?.filterHasAdvancedFilter}
            >
              {state.tableData?.fields}
            </Table>

            {state.showModal && (
              <CardUpdate
                type={state.actionType}
                operation={cardManagementOptions[state.actionType as actionTypes]}
                close={() => setState({ showModal: false })}
              />
            )}

            {state.showInfoModal && (
              <Modal
                close={() => setState({ showInfoModal: false })}
                heading={`Card ${capitalize(cardData?.status)}`}
                size="sm"
                headerBottomBorder={false}
                showSecondButton={false}
                description={switchInfoDescription[cardData?.status]}
                content={
                  <p>
                    This card was {cardData?.status} due to the following reason(s):
                    <ul>
                      <li>
                        <strong>{capitalize(cardData?.card_event?.reason)}</strong>
                      </li>
                    </ul>
                  </p>
                }
              />
            )}
          </section>
        </section>
      </section>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />
    </div>
  );
}
