/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import Icon from '+containers/Dashboard/Shared/Icons';
import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { useFeedbackHandler, useReducerState } from '+hooks';
import APIRequest from '+services/api-services';
import Copyable from '+shared/Copyable';
import CustomRadio from '+shared/CustomRadio';
import ListDropdown from '+shared/ListDropdown';
import Modal from '+shared/Modal';
import { IError } from '+types';
import { IProductLimitData } from '+types/productTiers';
import { capitalizeRemovedash, cleanInput, EmailValidation, getDate, getTime, logError, maskBetweenSetRange } from '+utils';

import { ActionModalType } from './ActionModal';
import UpdateMerchantDetailsInput from './UpdatingMerchantDetails/UpdateMerchantDetailsInput';

import CompletedRejectIcon from '+assets/img/dashboard/completed-reject-icon.svg';
import CompletedTierIcon from '+assets/img/dashboard/completed-tier-icon.svg';
import DeleteIcon from '+assets/img/dashboard/delete.svg';
import MessageIcon from '+assets/img/dashboard/message-icon.svg';

import './index.scss';

const api = new APIRequest();
interface ISentinal {
  manageSentinal: Record<string, () => void>;
  enabled: boolean;
}

interface IMerchantModalsProps {
  close: () => void;
  type: string;
  details?: Record<string, string | number>;
  id: string;
  tabs?: Record<string, string>;
  setFeedbacks?: () => void;
  setModal?: (value: { modalType: string }) => void;
  info?: Record<string, string>;
  unsentFeedbacks?: string[];
  verifyMerchant: Record<string, () => Promise<void>>;
  updateMerchantBvn: Record<string, () => Promise<void>>;
  sentinal?: ISentinal;
  updateRiskStatus: Record<string, () => Promise<void>>;
  rejectMerchant: Record<string, () => Promise<void>>;
}

interface IMerchantDetailsError {
  data: { [key in 'merchantEmail' | 'merchantName']: { message: string } };
}

const MerchantModals = ({
  close,
  type,
  info,
  details,
  id,
  tabs,
  setFeedbacks,
  unsentFeedbacks,
  verifyMerchant,
  updateMerchantBvn,
  updateRiskStatus,
  setModal,
  sentinal
}: IMerchantModalsProps) => {
  const queryClient = useQueryClient();
  const { feedbackInit } = useFeedbackHandler();
  const [state, setState] = useState({
    feedback: '',
    feedbacks: [],
    riskListOpen: false
  });
  const [riskLevel, setRiskLevel] = useState(null);
  const [merchantClass, setMerchantClass] = useState('');
  const [selectedTier, setSelectedTier] = useState<number | null>((details?.tier_level_id as number) || null);
  const [rejectedModalState, setRejectedModalState] = useReducerState({ value: '', otherReason: '' });
  const [states, setStates] = useReducerState({
    value: '',
    otherReason: '',
    merchantName: '',
    merchantEmail: '',
    nameEdited: false,
    emailEdited: false,
    updateHasError: false,
    emailIsInvalid: false
  });
  const [rejectedBvnState, setRejectedBvnState] = useReducerState({ value: '', otherReason: '' });
  const reasonsForRejection = [
    'Failure to provide adequate licensing documents',
    'We do not currently support this industry',
    'We found a record(s) of fraudulent history',
    'Custom reason'
  ];
  const reasonsForBvnRejection = [
    'BVN does not match any of the listed representatives',
    'BVN belongs to an already deactivated user',
    'Custom reason'
  ];
  const risk_Level = [
    { code: 'high_risk', name: 'High Risk' },
    { code: 'above_average_risk', name: 'Above Average Risk' },
    { code: 'medium_risk', name: 'Moderate Risk' },
    { code: 'low_risk', name: 'Low Risk' }
  ];

  const { data: merchantDetails } = useQuery(`MERCHANT_DETAILS_${id}`, () => api.getMerchants(id), {
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error fetching merchant details.`,
        type: 'danger',
        isClosable: true
      });
    }
  });
  useEffect(() => {
    if (details) {
      setStates({
        merchantName: details.name,
        merchantEmail: details.email
      });
    }
  }, [details]);

  useEffect(() => {
    if (states.merchantEmail !== details?.email) {
      setStates({ emailEdited: true });
      if (EmailValidation(states.merchantEmail)) {
        setStates({ emailIsInvalid: true });
      } else {
        setStates({ emailIsInvalid: false });
      }
    } else if (states.merchantName !== details?.name) {
      setStates({ nameEdited: true });
    } else {
      setStates({ emailEdited: false, nameEdited: false, emailIsInvalid: false });
    }
  }, [states.merchantEmail, states.merchantName]);

  const merchant_class = [
    { code: 'IGAMING_GLOBAL', name: 'Global Gaming' },
    { code: 'IGAMING_LOCAL', name: 'Local Gaming' },
    { code: 'ECOMM_GLOBAL', name: 'Ecommerce Global' },
    { code: 'ECOMM_LOCAL', name: 'Ecommerce Local' },
    { code: 'CRYPTO_GLOBAL', name: 'Global Crypto' },
    { code: 'CRYPTO_LOCAL', name: 'Local Crypto' }
  ];

  useEffect(() => {
    if (state.feedback !== '') {
      setState(prevState => ({ ...prevState, disabled: false }));
    }
  }, [state.feedback]);

  useEffect(() => {
    setFeedbacks(state.feedbacks);
  }, [state.feedbacks]);

  const modifyFeedback = feedback => {
    const data = {};
    const feedbackSwitch = {
      'business profile': 'business_profile',
      'settlement account': 'settlement_accounts'
    };
    // eslint-disable-next-line no-return-assign
    feedback.forEach(item => {
      const itemKey = feedbackSwitch[item.kycType.toLowerCase()] ?? item.kycType.toLowerCase();
      data[itemKey] = { note: item.feedback };
    });
    return data;
  };

  const treatMerchantStatus = useMutation(status => api.updateMerchantStatus(id, status), {
    onSuccess: () => {
      const feedbackMessage = {
        'suspend-merchant': 'Merchant has been suspended',
        'revoke-merchant': "Merchant's suspension has been revoked",
        'activate-merchant': 'Merchant has been activated',
        'deactivate-merchant': 'Merchant has been deactivated',
        'reject-merchant': 'Reject Merchant KYC'
      };

      feedbackInit({
        message: feedbackMessage[type],
        type: 'success'
      });
    },
    onError: () => {
      feedbackInit({
        message: `There has been an error updating this merchant's status. Please try again.`,
        type: 'danger'
      });
      close();
    },
    onSettled: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
    }
  });

  const treatMerchantSettlementAccount = useMutation(data => api.treatMerchantSettlementAccount(id, data.id, data.status, state.feedback), {
    onSuccess: () => {
      feedbackInit({
        message: 'Settlement account status has been updated',
        type: 'success'
      });
    },
    onError: () => {
      feedbackInit({
        message: "There has been an error updating this merchant's settlement bank account status. Please try again.",
        type: 'danger'
      });
      close();
    },
    onSettled: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
    }
  });

  const sendFeedbacks = useMutation(() => api.sendMerchantFeedback(id, { action: 'decline', feedback: modifyFeedback(unsentFeedbacks) }), {
    onSuccess: () => {
      setFeedbacks([], true);
      feedbackInit({
        message: 'Feedback Sent',
        type: 'success'
      });
    },
    onError: error => {
      logError(error);
      feedbackInit({
        message: `There has been an error sending feedback. Please try again.`,
        type: 'danger'
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
      queryClient.invalidateQueries('MERCHANT_STATISTICS');
    }
  });

  const { data: allTierLevels, isLoading: loadingTiers } = useQuery(['TIER_LEVELS'], () => api.getAllTierLevels(), {
    onError: () => {
      feedbackInit({
        message: 'There has been an error fetching tier levels.',
        type: 'danger'
      });
    },

    select: data => {
      const responseData = data?.data as IProductLimitData[];

      return responseData;
    }
  });

  const updateMerchantTierLevel = useMutation(() => api.updateMerchantTier(id, { tierLevelId: selectedTier as number }), {
    onError: (error: AxiosError<{ message: string }>) => {
      feedbackInit({
        message: error?.response?.data?.message || `There has been an error updating this merchant's level tier. Please try again.`,
        type: 'danger'
      });
      close();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
    }
  });
  const updateMerchantDetails = useMutation(
    () => {
      const userInfo = {
        merchantName: states.nameEdited ? states.merchantName : undefined,
        merchantEmail: states.emailEdited ? states.merchantEmail : undefined
      };
      return api.updateMerchantDetails(id, userInfo);
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries(`MERCHANT_DETAILS_${id}`);
        if (data?.data?.errorMessages?.merchantEmail || data?.data?.errorMessages?.merchantName) {
          close();
          setStates({ updateHasError: true });
          feedbackInit({
            message: `${data?.data?.errorMessages?.merchantEmail || data?.data?.errorMessages?.merchantName}`,
            type: 'danger',
            isClosable: true
          });
        } else {
          setStates({ updateHasError: false });
        }
      },
      onError: (error: IError) => {
        logError(error);
        setStates({ updateHasError: true });
        feedbackInit({
          message: `${
            error?.data?.merchantEmail?.message ||
            (error?.response?.data as IMerchantDetailsError)?.data.merchantEmail?.message ||
            (error?.response?.data as IMerchantDetailsError)?.data?.merchantName?.message ||
            error?.response?.data?.message
          }`,
          type: 'danger',
          componentLevel: true,
          isClosable: true
        });
      }
    }
  );

  const handleChange = (e: { target: { name: string; value: string } }) => {
    const { name, value } = e.target;
    setStates({ [name]: value });
    setStates({ updateHasError: false });
  };

  const modalActions = () => {
    let content;
    switch (type) {
      case ActionModalType.updateMerchantDetails:
        content = {
          heading: 'Edit Merchant Profile',
          description: 'Updated merchant details will be applied across all payment channels. ',
          size: 'md',
          content: (
            <div className="feedback-modal">
              <div className="form-group risk-container">
                <UpdateMerchantDetailsInput
                  label="Business Name"
                  name="merchantName"
                  placeholder={details?.name || 'enter a new business name'}
                  value={states.merchantName}
                  onChange={handleChange}
                  disabled={false}
                />
                <UpdateMerchantDetailsInput
                  label="Business Email Address"
                  name="merchantEmail"
                  placeholder={details?.email}
                  value={states.merchantEmail}
                  onChange={handleChange}
                  disabled={false}
                  error={states.emailIsInvalid}
                  errorMessage="Invalid email address"
                />
                <UpdateMerchantDetailsInput
                  label="Website"
                  name="website"
                  placeholder={merchantDetails?.info?.business_profile?.website || 'enter your business website'}
                  disabled
                />
                <UpdateMerchantDetailsInput
                  label="Phone Number"
                  name="phone_number"
                  placeholder={merchantDetails?.info?.contact?.support_phone}
                  disabled
                />
                <UpdateMerchantDetailsInput
                  label="Physical Address"
                  name="physical_address"
                  placeholder={
                    merchantDetails?.info?.business_profile?.business_address || details?.country?.name || 'enter your address here'
                  }
                  disabled
                />
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Apply',
          secondButtonDisable:
            (!states.emailEdited && !states.nameEdited) || !states.merchantEmail || !states.merchantName || states.emailIsInvalid,
          firstButtonAction: close,
          secondButtonAction: () => {
            setModal({ modalType: ActionModalType?.updateMerchantDetailsConfirm });
          },
          secondButtonActionIsTerminal: false
        };
        break;
      case ActionModalType.updateMerchantDetailsConfirm:
        content = {
          size: 'sm',
          completedModalSize: 'dialog',
          heading: 'Confirm Update',
          description:
            'Kindly confirm that you want to apply the update(s) to the merchant details. Update(s) will take effect immediately.',
          firstButtonText: 'Back',
          secondButtonText: 'Yes Apply',
          firstButtonAction: () => {
            setModal({ modalType: ActionModalType.updateMerchantDetails });
          },
          secondButtonAction: async () => {
            await updateMerchantDetails.mutateAsync({ merchantEmail: states.merchantEmail, merchantName: states.merchantName });
          },
          secondaryCompletedModal: !states.updateHasError,
          secondButtonActionIsTerminal: !states.updateHasError,
          completedHeading: 'Done',
          completedDescription: 'Merchant details has been updated successfully'
        };
        break;
      case ActionModalType.suspendMerchant:
        content = {
          heading: 'Suspend Merchant',
          description: 'Are you sure you want to suspend this merchant?',
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Suspend',
          firstButtonAction: close,
          secondButtonAction: () => {
            treatMerchantStatus.mutateAsync('suspended');
          },
          completedHeading: 'Merchant Suspended',
          completedDescription: `${details.name} will no longer have access to this business account.`,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
      case ActionModalType.revokeMerchant:
        content = {
          heading: 'Revoke Suspension',
          description: 'Are you sure you want to revoke suspension for this merchant?',
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Revoke',
          firstButtonAction: close,
          secondButtonAction: () => {
            treatMerchantStatus.mutateAsync('active');
          },
          completedHeading: 'Suspension Revoked',
          completedDescription: `${details?.name} will now have access to this business account.`,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
      case ActionModalType.deactivateMerchant:
        content = {
          heading: 'Deactivate Merchant',
          description: 'Are you sure you want to deactivate this merchant? This action cannot be undone.',
          content: null,
          themeColor: '#f32345',
          firstButtonText: 'Cancel',
          secondButtonText: 'Deactivate',
          firstButtonAction: close,
          secondButtonAction: () => {
            treatMerchantStatus.mutateAsync('deactivated');
          },
          completedHeading: 'Merchant Deactivated',
          completedDescription: `${details?.name} account has been deactivated.`,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
      case ActionModalType.activateMerchant:
        content = {
          heading: 'Activate Merchant',
          description: 'Are you sure you want to activate this merchant?',
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Activate',
          firstButtonAction: close,
          secondButtonAction: () => {
            treatMerchantStatus.mutateAsync('active');
          },
          completedHeading: 'Merchant Activated',
          completedDescription: `${details?.name} will now have access to this business account.`,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0'
        };
        break;
      case ActionModalType.feedBackModel:
        content = {
          heading: `Add feedback for ‘${capitalizeRemovedash(tabs?.activeKycTab)}’`,
          size: 'md',
          content: (
            <div className="feedback-modal">
              <p>Share your feedback explicitly stating what changes the merchant should make to their KYC for account verification.</p>
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Feedback Message
                </label>
                <textarea
                  name="feedback"
                  className="form-control modal-input"
                  rows="3"
                  maxLength="1500"
                  placeholder="Enter feedback"
                  value={state.feedback || ''}
                  onChange={e => setState({ ...state, feedback: cleanInput(e.target.value) })}
                />
              </div>
              <p>
                Note: Submitting feedback on this section implies that the merchant’s verification will be declined with reasons. This will
                return the merchant to the &quot;Unverified&quot; KYC stage.
                {tabs?.activeKycTab === 'Business Profile' ? (
                  <strong>The merchant would have to start their account verification process from the beginning!</strong>
                ) : (
                  'The merchant will need to redo the account verification based on this feedback.'
                )}
              </p>
            </div>
          ),
          firstButtonText: 'Discard',
          secondButtonText: 'Save',
          secondButtonDisable: state?.feedback?.length < 1,
          firstButtonAction: close,
          secondButtonAction: () => {
            setState({
              ...state,
              disabled: true,
              feedbacks: [...state.feedbacks, { feedback: state.feedback, kycType: tabs.activeKycTab, date: new Date() }]
            });
          },
          completedHeading: 'Feedback Saved',
          completedDescription: 'Your feedback has been successfully recorded.'
        };
        break;
      case ActionModalType.unSentModal:
        content = {
          heading: 'Unsent Feedback',
          showButtons: false,
          size: 'md',
          content: (
            <div className="feedback-modal">
              <p>Share your feedback explicitly stating what changes the merchant should make to their KYC for account verification.</p>
              {unsentFeedbacks?.map(feedback => (
                <div className="feedback-unsent" key={feedback.date}>
                  <span className="feedback-unsent__heading">Feedback on {feedback.kycType}</span>
                  <span className="feedback-unsent__details">{feedback.feedback}</span>
                  <div className="feedback-unsent__actions">
                    <span>{(getDate(feedback.date), getTime(feedback.date))}</span>
                    <button
                      type="button"
                      onClick={() => {
                        const newFeedbacks = unsentFeedbacks.filter(f => f.date !== feedback.date);
                        setFeedbacks(newFeedbacks, true);
                        if (newFeedbacks.length === 0) {
                          close();
                        }
                      }}
                    >
                      <span>Delete</span>
                      <img alt="" src={DeleteIcon} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ),
          firstButtonText: 'Close'
        };
        break;
      case ActionModalType.sendFeddback:
        content = {
          heading: 'Send Feedback',
          content: (
            <div>
              <p>Please confirm that you want to send all unsent feedback.</p>
              <p>
                The merchant will be moved back to the Unverified KYC stage and will need to redo their account verification based on the
                feedback shared.
              </p>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Confirm',
          secondButtonAction: () => sendFeedbacks.mutateAsync(),
          completedHeading: 'Feedback Sent',
          completedDescription: 'Feedback sent to merchant successfully.'
        };
        break;
      case ActionModalType.verifyMerchant:
        content = {
          heading: 'Verify Merchant',
          content: (
            <div>
              <p>Please confirm that you want to verify this merchant.</p>
              <p>This action would give the merchant access to the business account and will move them to the Verified KYC stage.</p>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Confirm',
          secondButtonAction: () => verifyMerchant.mutateAsync({ action: 'approve' }),
          completedHeading: 'Merchant Verified',
          completedDescription: 'Merchant will now have full access to their business account'
        };
        break;
      case ActionModalType.approveAccount:
        content = {
          heading: 'Verify bank account',
          content: (
            <div className="feedback-modal">
              <p>
                By validating this account, you certify that all other merchant data are correct and that it corresponds with other
                information provided by the merchant.
              </p>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Verify Account',
          firstButtonAction: close,
          secondButtonAction: () => treatMerchantSettlementAccount.mutateAsync({ status: 'active', id: info.settlementAcctId }),
          completedHeading: 'Account Verified',
          completedDescription: 'Merchant will now be able to make withdrawals and settlements to the bank account provided.'
        };
        break;
      case ActionModalType.rejectAccount:
        content = {
          heading: 'Reject this bank account',
          size: 'md',
          themeColor: '#f32345',
          content: (
            <div className="feedback-modal">
              <p>Include an explanation why the bank account submitted isn&apos;t eligible for accepting settlements or withdrawals.</p>
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Feedback Message
                </label>
                <textarea
                  name="feedback"
                  className="form-control modal-input"
                  rows={3}
                  maxLength={150}
                  placeholder="Enter feedback"
                  value={state.feedback || ''}
                  onChange={e => setState({ ...state, feedback: e.target.value })}
                />
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Decline Account',
          secondButtonDisable: state.feedback?.length < 1,
          firstButtonAction: close,
          secondButtonAction: () => treatMerchantSettlementAccount.mutateAsync({ status: 'rejected', id: info.settlementAcctId }),
          completedHeading: 'Account Declined',
          completedDescription: 'Merchant bank account was declined successfully, and they will be contacted to submit new information.'
        };
        break;
      case ActionModalType.changeRisk:
        content = {
          heading: 'Change Risk Rating',
          description: "Risk rating defines the nature of a merchant's operation and their level of compliance to business requirements..",
          size: 'md',
          content: (
            <div className="feedback-modal">
              <div className="form-group risk-container">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Risk Rating
                </label>
                <ListDropdown
                  list={risk_Level}
                  type="risk level"
                  name="risk"
                  className="risk-list"
                  searchDropdown={false}
                  displayText={false}
                  value={riskLevel?.name}
                  active={state.riskListOpen}
                  setActive={open => {
                    setState({ ...state, riskListOpen: open });
                  }}
                  setValue={value => {
                    setRiskLevel(value);
                  }}
                />
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Apply',
          secondButtonDisable: !riskLevel,
          firstButtonAction: close,
          secondButtonAction: () => {
            setModal({ modalType: ActionModalType.confirmRiskChange });
          },
          secondButtonActionIsTerminal: false
        };
        break;
      case ActionModalType.confirmRiskChange:
        content = {
          heading: 'Are you sure?',
          description: 'Updating the risk rating for this merchant can influence the configuration for settlement and rolling reserve.',
          content: <p>Do you want to proceed?</p>,
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Proceed',
          secondButtonAction: async () => {
            const data = {
              riskLevel: riskLevel?.code
            };
            await updateRiskStatus.mutateAsync(data);
          },
          completedHeading: 'Done',
          completedDescription: 'Risk rating for this merchant has been updated. '
        };
        break;
      case ActionModalType.rejectMerchant:
        content = {
          heading: 'Reject Merchant KYC',
          modalBodyClassName: 'border-top',
          noFormCenter: true,
          secondButtonActionIsTerminal: false,
          content: (
            <div className="rejected-modal-content">
              <p>
                By rejecting this merchant KYC, you confirm that the merchant has failed to meet the necessary compliance criteria. Note
                that this action cannot be reversed.
              </p>
              <div className="rejected-modal-message">
                <img alt="" src={MessageIcon} />
                <p>
                  Following KYC rejection, the impacted merchant will receive an email notification containing the selected or provided
                  feedback below.
                </p>
              </div>
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Why should merchant KYC be rejected?
                </label>
                <div className="rejected-modal-radio">
                  {reasonsForRejection.map(option => (
                    <CustomRadio
                      className="settlements-settings__merchants-checkbox"
                      type="checkbox"
                      checked={states.value === option}
                      text={option}
                      key={option}
                      onChange={() => setStates({ value: option })}
                    />
                  ))}
                </div>

                {states.value === 'Custom reason' && (
                  <>
                    <label htmlFor="email" style={{ fontWeight: 600 }}>
                      Custom Reason
                    </label>
                    <textarea
                      name="feedback"
                      className="form-control modal-input modal-input-rejected"
                      rows={3}
                      placeholder="Failure to comply with federal and regional laws."
                      value={states.otherReason || ''}
                      onChange={e => setStates({ ...states, otherReason: e.target.value })}
                    />
                  </>
                )}
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Proceed',
          secondButtonDisable: !states.value || (states.value === 'Custom reason' && !states.otherReason),
          firstButtonAction: close,
          secondButtonAction: () => {
            setModal({ modalType: ActionModalType.confirmRejectMerchant });
          },
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case ActionModalType.confirmRejectMerchant:
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          secondButtonColor: '#F32345',
          themeColor: '#F32345',
          completedImage: CompletedRejectIcon,
          heading: 'Reject Merchant KYC?',
          description: `Are you certain you want to reject the merchant's KYC? This action will result in them being unable to sign in again.`,
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Reject',
          secondButtonAction: () =>
            verifyMerchant.mutateAsync({
              action: 'reject',
              feedback: states.value === 'Custom reason' ? states.otherReason : states.value
            }),
          completedHeading: 'Merchant KYC Rejected',
          completedDescription: (
            <>
              <b>{details.name}</b> KYC has been rejected successfully.
            </>
          )
        };
        break;
      case ActionModalType.viewBvn:
        content = {
          heading: 'BVN Details',
          content: (
            <div className="bvn-details-modal">
              <Icon name="merchantIcon" />
              <p className="bvn-number">{maskBetweenSetRange(info?.bvnDetails?.number, 0, 5)}</p>
              <button type="button">
                <Copyable textToCopy={info?.bvnDetails?.number} text="Copy BVN" />
              </button>

              <div className="bvn-details">
                <div>
                  <p>Name</p>
                  <p>
                    <span>{info?.bvnDetails?.is_valid === false ? '-- --' : info?.bvnDetails?.name?.value || '--'}</span>
                  </p>
                </div>
                <div>
                  <p>Phone Number</p>
                  <p>
                    <span>{info?.bvnDetails?.phone?.value || '--'}</span>
                  </p>
                </div>
                <div>
                  <p>Date of Birth</p>
                  <p>
                    <span>{(info?.bvnDetails?.dob && getDate(info?.bvnDetails?.dob?.value)) || '--'}</span>
                  </p>
                </div>
              </div>
            </div>
          ),
          firstButtonText: 'Close',
          hideSecondButton: true,
          firstButtonAction: close
        };
        break;

      case ActionModalType.enableSentinal:
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          heading: 'Are you sure?',
          completedDescription: 'Sentinal status has been activated for merchant',
          description: `Please confirm that you want to activate this merchant as a sentinal merchant`,
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Activate',
          secondButtonAction: () =>
            sentinal.manageSentinal(sentinal.enabled === undefined ? { classification: merchantClass } : { status: 'enable' }),
          completedHeading: 'Sentinal Enabled'
        };
        break;

      case 'choose-merchant-class':
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          heading: 'Activate Sentinal',
          description: 'Please choose the type of sentinal merchant you want to activate for this merchant',
          content: (
            <div>
              <label htmlFor="merchant-class" className="merchant-class-label">
                Merchant Type{' '}
              </label>
              <select
                className="merchant-class"
                onChange={e => setMerchantClass(e?.target?.value)}
                value={Object.keys(merchant_class).find(key => merchant_class[key] === merchantClass)}
              >
                <option value="">Select Merchant Type</option>
                {merchant_class.map(item => (
                  <option key={item.code} value={item.code}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Activate Merchant',
          secondButtonAction: () => {
            setModal({ modalType: 'enable-sentinal' });
          },
          size: 'md',
          secondButtonActionIsTerminal: false,
          completedHeading: 'Sentinal Enabled',
          secondButtonDisable: !merchantClass
        };
        break;

      case 'disable-sentinal':
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          secondButtonColor: '#F32345',
          heading: 'Disable Sentinal?',
          completedDescription: 'Sentinal status has been disabled for merchant',
          description: `Are you sure you want to disable sentinal for this merchant?`,
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Disable',
          secondButtonAction: () => sentinal?.manageSentinal({ status: 'disable' }),
          completedHeading: 'Sentinal Disabled'
        };
        break;

      case 'reject-bvn':
        content = {
          secondaryCompletedModal: true,
          heading: 'Decline BVN',
          modalBodyClassName: 'border-top',
          noFormCenter: true,
          content: (
            <div className="rejected-modal-content">
              <p>Note that declining this BVN will prompt the merchant to provide another BVN. This action can not be undone.</p>
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 500 }}>
                  Select a reason for declining this BVN
                </label>
                <div className="rejected-modal-radio">
                  {reasonsForBvnRejection.map(option => (
                    <CustomRadio
                      className="settlements-settings__merchants-checkbox"
                      type="checkbox"
                      checked={rejectedBvnState.value === option}
                      text={option}
                      key={option}
                      onChange={() => setRejectedBvnState({ value: option })}
                    />
                  ))}
                </div>

                {rejectedBvnState.value === 'Custom reason' && (
                  <>
                    <label htmlFor="email" style={{ fontWeight: 600 }}>
                      Custom Reason
                    </label>
                    <textarea
                      name="feedback"
                      className="form-control modal-input modal-input-rejected"
                      rows={3}
                      placeholder="Enter a custom reason for declining BVN"
                      value={rejectedBvnState.otherReason || ''}
                      onChange={e => setRejectedBvnState({ ...rejectedBvnState, otherReason: e.target.value })}
                    />
                  </>
                )}
              </div>
            </div>
          ),
          firstButtonText: 'Back',
          secondButtonText: 'Decline',
          secondButtonColor: '#F32345',
          secondButtonDisable: !rejectedBvnState.value || (rejectedBvnState.value === 'Custom reason' && !rejectedBvnState.otherReason),
          firstButtonAction: close,
          secondButtonAction: async () => {
            const requestDetails = {
              action: 'decline',
              feedback: rejectedBvnState.value === 'Custom reason' ? rejectedBvnState.otherReason : rejectedBvnState.value
            };
            await updateMerchantBvn.mutateAsync(requestDetails);
          },
          completedImage: CompletedRejectIcon,
          completedHeading: 'Merchant BVN Declined',
          completedDescription: <>BVN has been declined successfully, and the merchant will be prompted to provide a new BVN.</>,
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'accept-bvn':
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          heading: 'Approve BVN',
          description: `Are you sure you want to approve BVN for this merchant?`,
          content: null,
          firstButtonText: 'Cancel',
          secondButtonText: 'Yes, Approve',
          secondButtonAction: async () => {
            const requestDetails = {
              action: 'approve'
            };
            await updateMerchantBvn.mutateAsync(requestDetails);
          },
          completedHeading: 'Done',
          completedDescription: <>Merchant BVN has been approved</>
        };
        break;
      case 'update-merchant-tier':
        content = {
          heading: 'Update Merchant Tier Level',
          description:
            'Select the tier to upgrade or downgrade this merchant. Watch for the prompt to carry over custom settings to the new tier if desired.',
          modalBodyClassName: 'border-top',
          noFormCenter: true,
          secondButtonActionIsTerminal: false,
          content: (
            <div className="rejected-modal-content">
              <div className="form-group">
                <label htmlFor="email" style={{ fontWeight: 600 }}>
                  Select Tier Level
                </label>
                <div className="rejected-modal-radio pl-0">
                  {loadingTiers && <LoadingPlaceholder type="table" content={5} />}
                  {allTierLevels?.map(option => (
                    <div key={option.id}>
                      <CustomRadio
                        className="settlements-settings__merchants-checkbox"
                        type="checkbox"
                        checked={selectedTier === option.id}
                        text={option.name}
                        onChange={() => setSelectedTier(option.id)}
                      />
                      {selectedTier === option.id && <p className="tier-option pl-4 ">{option.description}</p>}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ),
          firstButtonText: 'Cancel',
          secondButtonText: 'Update',
          secondButtonDisable: !selectedTier,
          firstButtonAction: close,
          secondButtonAction: () => {
            setModal?.({ modalType: 'confirm-update-merchant-tier' });
          },
          firstButtonColor: '#102649',
          firstButtonBackground: '#E0E7F0',
          size: 'md'
        };
        break;
      case 'confirm-update-merchant-tier':
        content = {
          secondaryCompletedModal: true,
          equalFooterBtn: true,
          secondButtonColor: '',
          heading: 'Confirm Update',
          description: `By clicking the update button below, you confirm the change to this merchant's profile to a new tier level and acknowledge the potential risks involved.`,
          content: null,
          firstButtonText: 'Back',
          firstButtonAction: () => {
            setModal?.({ modalType: 'update-merchant-tier' });
          },
          secondButtonText: updateMerchantTierLevel.isLoading ? (
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
          ) : (
            'Yes, Confirm'
          ),
          secondButtonAction: () => updateMerchantTierLevel.mutateAsync(),
          secondButtonActionIsTerminal: true,
          secondButtonDisable: !selectedTier,
          completedImage: CompletedTierIcon,
          completedHeading: 'Tier Update Successful',
          completedDescription: <>Merchant Tier has been updated successfully.</>
        };
        break;
      default:
        content = {};
        break;
    }

    return {
      close,
      size: 'sm',
      themeColor: '',
      ...content
    };
  };
  return <Modal {...modalActions()} />;
};

export default MerchantModals;
