type queriesParamType =
  | 'current'
  | 'limit'
  | 'status'
  | 'page'
  | 'general'
  | 'compliance'
  | 'kycTab'
  | 'tab'
  | 'verified'
  | 'kyc'
  | 'feedback'
  | 'ready'
  | 'signup'
  | 'rejected'
  | 'submitted'
  | 'sorterType'
  | 'subTab'
  | 'activeCurrency'
  | 'dateFrom'
  | 'dateTo'
  | 'keyword'
  | 'selectedConfig'
  | 'activeTab'
  | 'amountRange'
  | 'dateCreatedTo'
  | 'dateCreatedFrom'
  | 'selectData'
  | 'amountSubfilter';
// eslint-disable-next-line import/prefer-default-export


export const queriesParams: { [k in queriesParamType]: queriesParamType } = {
  current: 'current',
  limit: 'limit',
  status: 'status',
  page: 'page',
  general: 'general',
  compliance: 'compliance',
  kycTab: 'kycTab',
  tab: 'tab',
  verified: 'verified',
  kyc: 'kyc',
  feedback: 'feedback',
  ready: 'ready',
  signup: 'signup',
  rejected: 'rejected',
  submitted: 'submitted',
  sorterType: 'sorterType',
  subTab: 'subTab',
  activeCurrency: 'activeCurrency',
  dateFrom: 'dateFrom',
  dateTo: 'dateTo',
  keyword: 'keyword',
  selectedConfig: 'selectedConfig',
  activeTab: 'activeTab',
  amountRange: 'amountRange',
  dateCreatedTo: 'dateCreatedTo',
  dateCreatedFrom: 'dateCreatedFrom',
  selectData: 'selectData',
  amountSubfilter: 'amountSubfilter'
};

export const eventType = {
  kyc: 'Individual (KYC)',
  kyb: 'Business (KYB)',
};

export const statusIcon = {
  failed: {
    icon: 'invalid'
  },
  invalid: {
    icon: 'invalid'
  },
  valid: {
    icon: 'check'
  },
  undefined: {
    icon: 'unknown'
  }
};

export const regions = {
  ng: 'Nigeria',
  ke: 'Kenya',
  gh: 'Ghana',
  za: 'South Africa',
};


type storageDataKeyType = 'AVAILABLE_CURRENCIES' | 'SINGLE_MERCHANT';

export const storageDataKey: { [k in storageDataKeyType]: storageDataKeyType } = {
  AVAILABLE_CURRENCIES: 'AVAILABLE_CURRENCIES',
  SINGLE_MERCHANT: 'SINGLE_MERCHANT'
};

export const timeMode = {
  startTime: 'startTime',
  endTime: 'endTime'
};
export const hourData: string[] = ['12', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11'];
export const minuteData: string[] = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
  '47',
  '48',
  '49',
  '50',
  '51',
  '52',
  '53',
  '54',
  '55',
  '56',
  '57',
  '58',
  '59'
];
