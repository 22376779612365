import { SwappingType, Nullable } from 'src/types';

type statusColors = 'red' | 'green' | 'yellow' | 'orange' | 'blue' | 'light-blue' | 'dark-grey' | 'dark';

export type StatusType =
  | 'success'
  | 'accepted'
  | 'active'
  | 'low'
  | 'won'
  | 'partial'
  | 'settled'
  | 'failed'
  | 'declined'
  | 'inactive'
  | 'deactivated'
  | 'terminated'
  | 'deleted'
  | 'lost'
  | 'high'
  | 'auto_accepted'
  | 'pending'
  | 'escalated'
  | 'suspended'
  | 'mid'
  | 'email_unverified'
  | 'reversed'
  | 'approved'
  | 'processing'
  | 'manual'
  | 'requires_auth'
  | 'rejected';

export const switchStatus = (status: string): statusColors => {
  switch (status) {
    case 'success':
    case 'accepted':
    case 'active':
    case 'low':
    case 'won':
    case 'partial':
    case 'settled':
    case 'partially_accepted':
    case 'accepted_arbitration':
    case 'accepted_pre_arbitration':
    case 'partially_accepted_pre_arbitration':
    case 'enabled':
      return 'green';

    case 'failed':
    case 'declined':
    case 'inactive':
    case 'deactivated':
    case 'terminated':
    case 'deleted':
    case 'lost':
    case 'high':
    case 'auto_accepted':
    case 'declined_arbitration':
    case 'declined_pre_arbitration':
      return 'red';

    case 'pending':
    case 'escalated':
    case 'suspended':
    case 'mid':
    case 'email_unverified':
    case 'requires_auth':
    case 'require_auth':
    case 'pending_pre_arbitration':
    case 'delayed_pre_arbitration':
    case 'disabled':
      return 'yellow';

    case 'reversed':
    case 'approved':
      return 'blue';

    case 'processing':
    case 'processing_pre_arbitration':
      return 'orange';
    case 'manual':
      return 'light-blue';
    case 'rejected':
      return 'dark';
    case 'invalid':
    case 'invalid_pre_arbitration':
    default:
      return 'dark-grey';
  }
};

export const formatDocStatus = (status: Nullable<string>): Nullable<string> => {
  switch (status) {
    case 'under_review':
      return 'Under review';
    case 'approved':
      return 'Approved';
    case 'rejected':
      return 'Rejected';
    default:
      return status;
  }
};

export const formatKycStatus = (status: Nullable<string>): Nullable<string> => {
  switch (status) {
    case 'verification_incomplete':
      return 'Unverified';
    case 'verification_complete':
      return 'Complete';
    case 'review_required':
      return 'Ready for Review';
    default:
      return status;
  }
};

export const filterOutEmptyValues = (value: Record<any, any>): Record<any, any> => {
  const a = value;
  Object.keys(value).forEach(
    (key) =>
      (!a[key] || a[key] === 0 || (typeof a[key] === 'string' && a[key].trim() === '') || Object.entries(a[key]).length === 0) &&
      delete a[key]
  );
  return a;
};

/**
 * this function takes in an object and an array of a list of properties
 * to remove from the object and then return a new object without those properties.
 * @param {Object} object
 * @param {array} notAllowed
 */
type filterOutFromObject<T extends string | number | symbol, P> = (object: Record<T, P>, notAllowed: any[]) => Record<T, P>;

export const filteredOutObjectProperty: filterOutFromObject<any, any> = (object, notAllowed) =>
  object && Object.keys(object).length > 0
    ? Object.keys(object)
        .filter((key) => !notAllowed.includes(key))
        .reduce((obj, key) => {
          return {
            ...obj,
            [key]: object[key]
          };
        }, {})
    : {};

export const selectedArrayItem = (key: string, array: any[] = []) => {
  if (key === 'All') return [];
  const sel = [...array];
  const find = sel.indexOf(key);
  if (find > -1) {
    sel.splice(find, 1);
  } else {
    sel.push(key);
  }
  return sel;
};

export const swapArrayPositions: SwappingType<unknown> = (array, a, b) => {
  if (!array?.length || array?.length <= 1) return array;
  const newArray = [...array];
  [newArray[a], newArray[b]] = [newArray[b], newArray[a]];
  return newArray;
};

export const currentProductConfigSettings = ['transaction_limit', 'channels'];

/** @dev TODO: Extend this to consider object comparison */
export const returnUniqueItemsInArray = <T>(array: T[]): T[] => {
  return [...new Set(array)];
};
