import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'react-use';

import Modal, { IModalProps } from '+containers/Dashboard/Shared/Modal';
import ReactSelectDropdown from '+containers/Dashboard/Shared/ReactSelectDropdown';
import { useFeedbackHandler, useSearchQuery } from '+hooks';
import APIRequest from '+services/api-services';
import usePartnerBalanceStore from '+store/partnerBalanceStore';
import { AddFundsModalType, IPartnerBalance, IPartnerBalanceOption, IPartnerFundingValues } from '+types';
import { backwardAmountInput, capitalize, cleanInput, formatAmount, formatWithCommas, logError } from '+utils';

import '../index.scss';

const api = new APIRequest();

const AddFundsModal = ({
  type,
  accountId,
  setter
}: {
  type: AddFundsModalType;
  accountId: string;
  setter: (value: AddFundsModalType) => void;
}) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const searchQuery = useSearchQuery();
  const { feedbackInit } = useFeedbackHandler();
  const [amount, setAmount] = useState<string>();
  const [source, setSource] = useState<number | null>();
  const [initiatedTransactionReference, setInitiatedTransactionReference] = useState<string | null>(null);
  const [validation, setValidation] = useState({
    amountError: false,
    maxTransferError: false,
    maxDepositError: false,
    maxBalanceError: false,
    disable: true
  });
  const activeCurrency = searchQuery.value.currency ?? 'NGN';
  const partnerBalances = usePartnerBalanceStore(state => state.partnerBalances);

  const partnersBalance = Object.values(partnerBalances).map((item: IPartnerBalance) => ({
    value: item.id,
    label: item.institution_name,
    balance: item.available_balance,
    status: item.active,
    destination_name: item.destination_name,
    threshold: item.threshold
  }));

  const sourceAccount = partnersBalance?.find((item: IPartnerBalanceOption) => item.value === source);
  const destinationAccount = partnersBalance?.find((item: IPartnerBalanceOption) => item.value === +accountId);
  const partnersBalanceOptions = partnersBalance?.filter((item: IPartnerBalanceOption) => item.value !== +accountId);

  const fundPartnerBalance = useMutation((value: IPartnerFundingValues) => api.fundPartnerBalance(value), {
    onSuccess: data => {
      setInitiatedTransactionReference(data?.data?.reference);
      queryClient.invalidateQueries(`${accountId}_BALANCE_HISTORY`);
      queryClient.invalidateQueries(`${accountId}_BALANCE`);
    },
    onError: (error: { response: { data: { data: { amount: { message: string } } }; message: string } }) => {
      logError(error);

      feedbackInit({
        message: capitalize(
          `${error?.response?.data?.data?.amount.message ?? error?.response?.message}` ||
            "There has been an error funding this partner's balance"
        ),
        type: 'danger',
        componentLevel: true
      });
    }
  });
  const validateAmount = () => {
    if (amount && +amount > (sourceAccount?.balance ?? 0)) {
      setValidation(prev => ({ ...prev, amountError: true, disable: true }));
    } else if (
      amount &&
      sourceAccount?.threshold?.max_transfer_amount !== undefined &&
      +amount > sourceAccount?.threshold?.max_transfer_amount
    ) {
      setValidation(prev => ({ ...prev, maxTransferError: true, disable: true }));
    } else if (amount && +amount > (destinationAccount?.threshold?.max_deposit_amount ?? 0)) {
      setValidation(prev => ({ ...prev, maxDepositError: true, disable: true }));
    } else if (amount && (destinationAccount?.balance ?? 0) + Number(amount) > (destinationAccount?.threshold?.max_balance ?? 0)) {
      setValidation(prev => ({ ...prev, maxBalanceError: true, disable: true }));
    } else if ((amount && +amount <= 1) || !source || !amount || Number.isNaN(+amount)) {
      setValidation(prev => ({ ...prev, amountError: false, disable: true }));
    } else {
      setValidation({ amountError: false, disable: false, maxTransferError: false, maxDepositError: false, maxBalanceError: false });
    }
  };

  useDebounce(() => validateAmount(), 200, [amount, source]);

  const switchAddFundsModal = (kind: AddFundsModalType) => {
    let content;
    switch (kind) {
      case 'add-funds':
        content = {
          heading: 'Add funds to balance',
          description: 'Enter the desired amount you want to add.',
          content: (
            <>
              <div className="form-group">
                <ReactSelectDropdown<IPartnerBalanceOption>
                  label="Source of Funds"
                  onChange={value => setSource(Number(value))}
                  placeholder="-- Select Source --"
                  options={partnersBalanceOptions}
                  value={source}
                  // eslint-disable-next-line react/no-unstable-nested-components
                  formatOptionLabel={value => <PartnerOption value={value} />}
                />
              </div>
              <div className="form-group">
                <label htmlFor="add-funds">Amount</label>
                <input
                  type="numeric"
                  id="add-funds"
                  className={`form-control ${validation.amountError ? 'is-invalid' : ''}`}
                  placeholder="Enter amount"
                  value={formatWithCommas(amount)}
                  onChange={e => {
                    const formattedAmount = backwardAmountInput(cleanInput(e.target.value.replace(/,/g, '')));
                    setAmount(String(formattedAmount));
                  }}
                />
                {validation.amountError && <span className="form-error">Amount must be less than or equal to source balance.</span>}
                {validation.maxTransferError && (
                  <span className="form-error">{`Amount is greater than transfer threshold of ${formatAmount(
                    sourceAccount?.threshold?.max_transfer_amount ?? 0
                  )}.`}</span>
                )}
                {validation.maxDepositError && (
                  <span className="form-error">{`Amount is greater than amount of ${formatAmount(
                    destinationAccount?.threshold?.max_deposit_amount ?? 0
                  )} the destination can receive.`}</span>
                )}
                {validation.maxBalanceError && (
                  <span className="form-error">{`Amount is greater than amount of ${formatAmount(
                    destinationAccount?.threshold?.max_balance ?? 0
                  )} balance the destination can have.`}</span>
                )}
              </div>
            </>
          ),
          secondButtonText: 'Next',
          secondButtonAction: () => setter('confirm'),
          secondButtonDisable: validation.disable
        };
        break;
      case 'confirm':
        content = {
          heading: 'Confirm Transaction',
          description: 'Please review the transaction information and confirm that you want to proceed with this transaction.',
          content: (
            <p>
              You are about to add <strong>{`${activeCurrency} ${formatAmount(amount ?? 0)}`}</strong> from {sourceAccount?.label} to{' '}
              <strong>{` ${destinationAccount?.label}`}</strong>.
            </p>
          ),
          firstButtonAction: () => setter('add-funds'),
          secondButtonText: 'Yes, Confirm',
          secondButtonAction: async () => {
            if (!source && !amount) return;
            await fundPartnerBalance.mutateAsync({
              source_id: Number(source),
              destination_id: +accountId,
              amount: Number(amount)
            });
          },
          completedHeading: 'Transaction Successful',
          completedDescription: 'Your payment has been created.',
          equalFooterBtn: true,
          headerBottomBorder: false,
          completedActionText: 'View payment details',
          completedAction: () => {
            history.push(`/dashboard/partners-balance/transaction/${initiatedTransactionReference}`);
            setter(null);
          }
        };
        break;
      default:
        return null;
    }
    return {
      close: () => {
        setter(null);
      },
      size: 'md',
      secondButtonActionIsTerminal: kind === 'confirm',
      secondaryCompletedModal: true,
      ...content
    };
  };
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Modal {...(switchAddFundsModal(type) as IModalProps)} />;
};

export default AddFundsModal;

const PartnerOption = ({ value }: { value: IPartnerBalanceOption }) => {
  return (
    <p className="select-option">
      <span>{`${value.destination_name} (${value?.label})`}</span> <span>{`NGN ${formatAmount(value?.balance ?? 0)}`}</span>
    </p>
  );
};
