import React from 'react';
import Table from '+containers/Dashboard/Shared/Table';
import { useSearchQuery } from '+hooks';
import { capitalizeFirst, getDate, getTime } from '+utils';
import { history } from '+utils';
import APIRequest from '+services/api-services';
import { IMerchant, IdentityTablePropsT } from '+types/identity';
import OfficeIcon from '+assets/img/dashboard/office-icon.svg'
import './index.scss';

const api = new APIRequest(process.env.REACT_APP_MIDDLEWARE_API_BASE);

export default function MerchantsTable({ resolvedData, isFetching, refetch, exportData }: IdentityTablePropsT<IMerchant>) {
  const searchQuery = useSearchQuery();
  const currentPage = searchQuery?.value?.page || 1;

  const getDetailsLink = (reference: string) => `/dashboard/identity/merchants/${reference}`;

  const renderFields = (merchant: IMerchant[]) => {
    return (
      <div className="nav-content active">
        <div className="element-box-tp">
          <div>
            {merchant?.map((each) => {
              return (
                <div
                  key={`merchant_${each.name}`}
                  className="div-table --row imt"
                  role="button"
                  tabIndex={0}
                  onClick={() => history.push(getDetailsLink(each.reference))}
                  onKeyDown={() => history.push(getDetailsLink(each.reference))}
                >
                  <div>
                    <span className="body-row-header">Merchant:</span>
                    <img src={OfficeIcon} alt="Office Icon" />

                    <span className="imt__merchant-name">{capitalizeFirst(each?.name)}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Service Status:</span>
                    <span className={`identity__service-status --${each?.active ? 'enabled' : 'disabled'}`}></span>
                    <span>{each?.active ? 'Enabled' : 'Disabled' || 'Not available'}</span>
                  </div>
                  <div>
                    <span className="body-row-header">Date Added:</span>
                    <span>{getDate(each?.dateAdded)}</span>
                    <span className="annotation" style={{ marginLeft: '5px', color: 'rgba(74, 74, 74, 0.5)' }}>
                      {getTime(each?.dateAdded)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  return (
    <section className="element-box-tp mt-5">
      <Table
        className={'imt'}
        tableHeadings={['Merchant', 'Service Status', 'Date Added']}
        current={parseInt(currentPage + '', 10)}
        totalItems={resolvedData?.paging ? resolvedData.paging.total_items : 0}
        pageSize={resolvedData?.paging ? resolvedData.paging.page_size : 0}
        actionFn={(current) => searchQuery.setQuery({ page: String(current) })}
        limitAction={(limit) => searchQuery.setQuery({ limit: String(limit) })}
        annotation={'merchants'}
        emptyStateHeading={`There are no merchants yet.`}
        filterExportName={'Export Identity Merchants'}
        filterName={`All merchants with access (${resolvedData?.paging?.total_items || 0})`}
        filterExportAction={exportData}
        type={'identity-merchants'}
        filterKeywordPlaceholder={'Search by Merchant ...'}
        filterTotalCount={resolvedData?.paging?.total_items}
        filterShowExport={true}
        loading={isFetching}
        data={resolvedData?.data || []}
        filterHasAdvancedFilter
      >
        {renderFields(resolvedData?.data)}
      </Table>
    </section>
  );
}
