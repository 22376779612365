import React from 'react';
import { Field, Formik } from 'formik';

import CustomCheckbox from './CustomCheckbox';
import CustomRadio from './CustomRadio';

interface Ivalue {
  type: string;
  range: number[];
  vat_inclusive: boolean;
  value: number;
  cap: number;
  default: boolean;
  scheme: string;
  currency: string;
  percent: number;
  maximum: number;
  minimum: number;
  amount: number;
  vat: boolean;
}

interface IErrors {
  cap: string;
  percent: string;
  amount: string;
  minimum: string;
  maximum: string;
  scheme: string;
}

interface IEditFeesProps {
  setDisabled: (e: boolean) => boolean;
  setValues: (e: Ivalue) => void;
  oldValue: Ivalue;
  activeCurrency: string;
}

const paymentChannels = [
  { label: 'All', value: 'ALL' },
  { label: 'FPS', value: 'FPS' },
  { label: 'SEPA', value: 'SEPA' },
  { label: 'ACH', value: 'ACH' },
  { label: 'CHAPS', value: 'CHAPS' },
  { label: 'BACS', value: 'BACS' }
];
export default function EditFeesComponent({ setDisabled, setValues, oldValue, activeCurrency }: IEditFeesProps) {
  const initialValues: Ivalue = {
    type: oldValue.type,
    minimum: oldValue.range[0],
    maximum: oldValue.range[1],
    percent: oldValue.type !== 'flat' ? oldValue.value : 0,
    amount: oldValue.type === 'flat' ? oldValue.value : 0,
    cap: oldValue.cap || 0,
    vat: oldValue.vat_inclusive || false,
    scheme: oldValue.scheme,
    default: oldValue.default || false,
    range: [],
    vat_inclusive: false,
    value: 0,
    currency: ''
  };
  return (
    <Formik
      initialValues={initialValues}
      validate={values => {
        const errors: IErrors = {
          cap: '',
          percent: '',
          amount: '',
          minimum: '',
          maximum: '',
          scheme: ''
        };
        if (values.cap < 0) errors.cap = 'A valid fee cap must be greater than or equal to 0';
        if ((values.percent === '' && values.type === 'percent') || values.percent < 0 || values.percent >= 100)
          errors.percent = 'A valid percent rule is required';
        if (!values.vat && values.percent + 7.5 >= 100) errors.percent = 'Since vat is not included, this percent rule exceeds 100';

        if (!values.amount && values.type === 'flat') errors.amount = 'A valid flat amount rule is required';
        if (values.amount < 0 && values.type === 'flat') errors.amount = 'A valid flat amount must be greater than or equal to 0';

        if (Number.isNaN(values.minimum)) errors.minimum = 'A minimum value has to be set for this fee rule';
        if (Number.isNaN(values.maximum) || values.maximum < 1) errors.maximum = 'A valid maximum value has to be set for this fee rule';
        if (values.minimum < 0) errors.minimum = 'A minimum value must be greater than or equal to 0';
        if (values.maximum < 0) errors.maximum = 'A maximum value must be greater than or equal to 0';

        if (!values.vat && values.type === 'flat' && 1.075 * values.amount > values.maximum)
          errors.amount = 'A valid flat amount (vat inclusive) must be less than the maximum value';

        if (values.cap && values.cap > values.maximum) errors.cap = 'A valid fee cap must be less than the maximum value';
        if (values.type === 'flat' && values.amount > values.maximum)
          errors.amount = 'A valid flat amount must be less than the maximum value';

        if (values.minimum === values.maximum || values.minimum > values.maximum)
          errors.maximum = 'Maximum amount must be greater than the minimum amount';

        const disabled = () => {
          if (
            (values.type === 'percent' && errors.percent) ||
            (values.type === 'flat' && errors.amount) ||
            errors.minimum ||
            errors.maximum ||
            errors.cap
          )
            return true;
          return false;
        };
        setDisabled(disabled());
        setValues(values);
        return errors;
      }}
    >
      {({ values, errors, touched, setFieldValue }) => {
        return (
          <div className="form-center col-sm-12">
            <fieldset className="my-1">
              <div className="form-group" hidden={!['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <Field name="trxnChannel">
                  {({ field }) => (
                    <>
                      <label htmlFor="payment-type">Payment Channel</label>
                      <select
                        {...field}
                        className="form-control"
                        name="scheme"
                        value={values.scheme}
                        onChange={e => {
                          setFieldValue('scheme', e.target.value);
                        }}
                      >
                        {paymentChannels?.map(method => (
                          <option value={method.value} key={method.value}>
                            {method.label}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                </Field>
              </div>

              <div className="row">
                <div className="col-sm-12">
                  <p>What transaction range should this fee apply to?</p>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="minimum">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">From</label>
                          <input
                            {...field}
                            name="minimum"
                            value={values.minimum}
                            placeholder={values.minimum}
                            className="form-control"
                            type="number"
                          />
                          {touched.minimum && errors.minimum && (
                            <div className="input__errors">
                              <p>{errors.minimum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="maximum">
                      {({ field }) => (
                        <>
                          <label htmlFor="amount">To</label>
                          <input {...field} placeholder={values.maximum} name="maximum" className="form-control" type="number" />
                          {touched.maximum && errors.maximum && (
                            <div className="input__errors">
                              <p>{errors.maximum}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <hr className="mt-1" />

              <div className="form-group mb-3" hidden={!['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <CustomCheckbox
                  text="Set as the default fee for this payment channel?"
                  checked={values.default}
                  onChange={value => {
                    setFieldValue('default', value);
                  }}
                  className="default-payment-channel"
                />
              </div>

              <hr className="mt-1" />

              <div className="form-group">
                <div className="row">
                  <div className="col-sm-12">
                    <p>What type of fee are you charging?</p>
                  </div>
                  <div className="col-sm-3">
                    <CustomRadio
                      text="Flat fee"
                      name="type"
                      checked={values.type === 'flat'}
                      onChange={() => {
                        setFieldValue('type', 'flat');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                  <div className="col-sm-4">
                    <CustomRadio
                      text="Percentage Fee"
                      name="type"
                      checked={values.type === 'percent'}
                      onChange={() => {
                        setFieldValue('type', 'percent');
                        setFieldValue('cap', 0);
                        setFieldValue('percent', '');
                        setFieldValue('amount', '');
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="row" hidden={values.type === 'flat'}>
                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="percent">
                      {({ field }) => (
                        <>
                          <label htmlFor="percent">Transaction fee (%)</label>
                          <input {...field} name="percent" className="form-control" type="number" placeholder={0} />
                          {touched.percent && errors.percent && (
                            <div className="input__errors">
                              <p>{errors.percent}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <Field name="cap">
                      {({ field }) => (
                        <>
                          <label htmlFor="cap">Max. Fee Limit (Capped at)</label>
                          <input {...field} name="cap" className="form-control" type="number" placeholder={values.cap} />
                          {touched.cap && errors.cap && (
                            <div className="input__errors">
                              <p>{errors.cap}</p>
                            </div>
                          )}
                        </>
                      )}
                    </Field>
                  </div>
                </div>
              </div>

              <div className="form-group" hidden={values.type !== 'flat'}>
                <Field name="amount">
                  {({ field }) => (
                    <>
                      <label htmlFor="amount">Flat Transaction Fee</label>
                      <input {...field} name="amount" className="form-control" type="number" placeholder={values.amount} />
                      {touched.amount && errors.amount && (
                        <div className="input__errors">
                          <p>{errors.amount}</p>
                        </div>
                      )}
                    </>
                  )}
                </Field>
              </div>

              <div className="form-group mb-3" hidden={['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <CustomCheckbox
                  text="Tax (VAT) has been considered and included in this fee"
                  checked={values.vat}
                  onChange={value => {
                    setFieldValue('vat', value);
                  }}
                />
              </div>

              <hr />

              <div className="sub-info-detail" hidden={['GBP', 'EUR', 'USD'].includes(activeCurrency)}>
                <span>
                  {values.vat
                    ? 'This fee already includes the VAT of 7.5%, which means that the VAT will not be added to the fee when the customer is charged.'
                    : `This fee doesn't include the VAT of 7.5%, which means that the VAT will be added to the fee when the customer is
                    charged.`}
                </span>
              </div>
            </fieldset>
          </div>
        );
      }}
    </Formik>
  );
}
