import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';

import { ISODateStringType, Nullable } from '+types';

dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);

export const getTime = (date: Nullable<string>): string => dayjs(date).format('LT');
export const specialisedDate = (date: Nullable<string>, format: string): string => dayjs.utc(date).format(format);
export const getDate = (date: Nullable<string | number>): string => dayjs(date).format('D MMM YYYY');
export const getFullDate = (date: Nullable<string>): string => dayjs(date).format('D MMMM, YYYY');
export const getHumanTime = (date: Nullable<string>): string => dayjs(date).fromNow();
export const getPresentDate = (): { dateFrom: string; dateTo: string } => {
  const presentDay = {
    dateFrom: dayjs(new Date()).format('YYYY-MM-DD'),
    dateTo: dayjs(new Date()).format('YYYY-MM-DD')
  };
  return presentDay;
};
export const getDateAndTime = (date: Nullable<string>): string => dayjs(date).format('D MMM YYYY LT');

export const durationToday = (): Nullable<string>[] => {
  const initDate = dayjs().format('YYYY-MM-DD');
  return [initDate, null];
};

export const durationWeek = (): string[] => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const durationMonth = (prevMonth = 1): string[] => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(prevMonth, 'month').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const durationYear = (): string[] => {
  const initDate = dayjs().format('YYYY-MM-DD');
  const prevDate = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  return [prevDate, initDate];
};

export const daysfromToday = (date: string): string => {
  const today = new Date();
  const actualDate = new Date(date);
  // If dates are the same
  if (dayjs(today).format('MMMM D YYYY') === dayjs(actualDate).format('MMMM D YYYY')) {
    return 'Today';
  }
  // Reset to the beginning of the day
  today.setHours(0);
  if (actualDate > today) {
    return dayjs(date).from(dayjs(today));
  }
  const daysToValue = dayjs(today).to(dayjs(date));
  if (daysToValue.startsWith('a')) {
    return `1 ${daysToValue.slice(2)}`;
  }
  return daysToValue;
};

export const getDateQuery = (query = '', key: string, value: string): Nullable<string> => {
  if (query.includes('_before') && key.includes('From')) {
    return null;
  }
  if (query.includes('_before') && key.includes('To')) {
    return dayjs(value).subtract(1, 'days').format('YYYY-MM-DD');
  }
  if (query.includes('_after') && key.includes('From')) {
    return dayjs(value).add(1, 'days').format('YYYY-MM-DD');
  }
  if (query.includes('_after') && key.includes('To')) {
    return null;
  }
  if (query.includes('last_') && key.includes('To')) {
    return dayjs().format('YYYY-MM-DD');
  }
  if (query.includes('last_seven_days') && key.includes('From')) {
    return dayjs().subtract(7, 'days').format('YYYY-MM-DD');
  }
  if (query.includes('last_thirty_days') && key.includes('From')) {
    return dayjs().subtract(30, 'days').format('YYYY-MM-DD');
  }
  if (query.includes('today')) {
    return dayjs().format('YYYY-MM-DD');
  }
  return value ? dayjs(value).format('YYYY-MM-DD') : value;
};

export const getCustomiseDateTimeFormat = (date: Nullable<string>, format: string) => {
  if (date) {
    const utcTime = dayjs.utc(date).local();
    return dayjs(utcTime).format(format);
  }
};
export const getTwoDigitDate = (date: ISODateStringType) => dayjs.utc(date).format('MM / YY');
