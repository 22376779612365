/* eslint-disable camelcase */
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import EmptyState from '+shared/EmptyState';
import APIRequest from '+services/api-services';
import PaginationComponent from '+shared/Pagination';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import {
  logError,
  capitalizeRemovedash,
  getDate,
  getTime,
  durationToday,
  durationMonth,
  durationWeek,
  isAllowed,
  specialisedDate
} from '+utils';
import useFeedbackHandler from '+hooks/useFeedbackHandler';
import useSetUserAccess from '+hooks/useSetUserAccess';
import './index.scss';

const api = new APIRequest();
export default function AuditLogs() {
  const { id } = useParams();
  const { feedbackInit } = useFeedbackHandler();

  const [state, setState] = useState({
    paginationPage: 1,
    tabs: { today: durationToday(), this_week: durationWeek(), this_month: durationMonth(), all_time: [null, null] },
    activeTab: 'all_time',
    limit: 25
  });

  const { data, isLoading, isFetching, refetch } = useQuery(
    [`MERCHANT_AUDIT_LOGS_${id}`, state.paginationPage, state.limit, ...state.tabs[state.activeTab]],
    () => api.getMerchantLogs(id, state.paginationPage, state.limit, ...state.tabs[state.activeTab]),
    {
      onError: (error) => {
        if (error) {
          logError(error);
          feedbackInit({
            message: `There has been an error getting activity for the specified time period`,
            type: 'danger',
            action: {
              action: () => refetch(),
              name: 'Try again'
            }
          });
        }
      }
    }
  );

  const paging = data?.paging;
  const paginate = (currentPage) => {
    setState({ ...state, paginationPage: currentPage });
  };

  const renderEvents = () => {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {data?.data?.length !== 0 ? (
          <>
            <table className="table table-lightborder auditLogTable">
              <tbody>
                {data?.data.map((event) => (
                  <tr key={Math.floor(1000 + Math.random() * 9000)}>
                    <td style={{ width: '22%' }}>{`${getDate(event.created_at)}, ${specialisedDate(event.created_at, 'hh:mm A')}`}</td>
                    <td style={{ width: '52%' }}>{event.description}</td>
                    <td className="text-right lighter">{event.event_id}</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {data?.data?.map((event) => (
              <div key={Math.floor(1000 + Math.random() * 9000)} className="div-table --row auditLogTable mobile">
                <div className="--txn-status">
                  <span className="body-row-header">Timestamp:</span>
                  <span>{`${getDate(event.created_at)}, ${specialisedDate(event.created_at, 'hh:mm A')}`}</span>
                </div>
                <div>
                  <span className="body-row-header">Event:</span>
                  <span>{event.description}</span>
                </div>
                <div>
                  <span className="body-row-header">Event ID:</span>
                  <span>{event.event_id}</span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <EmptyState heading="There are no logs yet" />
        )}
      </>
    );
  };

  return (
    <div className="element-box">
      <div className="os-tabs-w mb-3">
        <div className="os-tabs-controls">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button type="button" data-toggle="tab" className="nav-link active" style={{ paddingBottom: '15px' }}>
                <span className="tab-label">Events</span>
              </button>
            </li>
          </ul>
          <ul className="nav nav-pills smaller d-none d-md-flex" style={{ paddingBottom: '10px' }}>
            {Object.keys(state.tabs).map((tab) => {
              return (
                <li className="nav-item" key={Math.floor(1000 + Math.random() * 900)}>
                  <button
                    data-toggle="tab"
                    type="button"
                    onClick={() => setState({ ...state, activeTab: tab })}
                    className={`nav-link  ${state.activeTab === tab && 'active'}`}
                  >
                    {capitalizeRemovedash(tab)}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="tab-content">
          <div className="tab-pane active" id="tab_activity">
            <div className="table-responsive">
              <table className="table table-lightborder auditLogTable">
                <thead className="shaded">
                  <tr>
                    <th className="lighter" style={{ width: '22%' }}>
                      Timestamp
                    </th>
                    <th className="lighter" style={{ width: '52%' }}>
                      Event
                    </th>
                    <th className="text-right lighter">Event ID</th>
                  </tr>
                </thead>
              </table>
              {isLoading || isFetching ? <LoadingPlaceholder type="table" background="#f5f6f6" /> : renderEvents()}
              {(!isLoading || !isFetching) && data.length > 0 && (
                <PaginationComponent
                  currentPage={paging?.current}
                  pagingTotalItems={paging?.total_items}
                  pageSize={paging?.page_size}
                  action={paginate}
                  annotation="actions"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
