import React, { useEffect, useRef } from 'react';

import info from '+assets/img/dashboard/tooltip.svg';

import './index.scss';

interface IToolTipProps {
  type: string;
  message: React.ReactNode;
  image?: React.ReactNode;
  children?: React.ReactNode;
  hasFullWidth?: boolean;
  centered?: boolean;
  disabled?: boolean;
  noPadding?: boolean;
  className?: string;
  contentClassName?: string;
}
function ToolTip({
  type,
  message,
  image = info,
  children,
  hasFullWidth,
  centered,
  disabled,
  noPadding,
  className,
  contentClassName
}: IToolTipProps) {
  const tooltipElement = useRef(null);
  const showToolTip = status => {
    if (disabled) return;
    const element = tooltipElement.current;
    if (status) {
      element.classList.add('visible');
      element.setAttribute('aria-hidden', 'true');
      element.parentNode.classList.add('visible');
      return;
    }
    element.classList.remove('visible');
    element.setAttribute('aria-hidden', 'false');
    element.parentNode.classList.remove('visible');
  };

  return (
    <div className={`text-tooltip-w ${hasFullWidth ? 'w-100 pr-2 no-transform' : ''} ${className}`}>
      {image && !children ? (
        <img
          src={image}
          alt="info icon"
          className="text-tooltip--image"
          aria-hidden="true"
          aria-labelledby="text-tooltip--image"
          aria-describedby={`${type}-info`}
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          tabIndex="0"
          onMouseOver={() => showToolTip(true)}
          onFocus={() => showToolTip(true)}
          onBlur={() => showToolTip(false)}
          onMouseLeave={() => showToolTip(false)}
        />
      ) : null}
      {children && (
        <div
          tabIndex="0"
          onMouseOver={() => showToolTip(true)}
          onFocus={() => showToolTip(true)}
          onBlur={() => showToolTip(false)}
          onMouseLeave={() => showToolTip(false)}
          style={{ padding: noPadding ? 0 : undefined }}
        >
          {children}
        </div>
      )}

      <div
        className={`text-tooltip--content ${centered ? 'centered' : ''} ${contentClassName}`}
        id={`${type}-info`}
        role="tooltip"
        aria-hidden={false}
        ref={tooltipElement}
        aria-label="text-tooltip-message"
      >
        {message}
      </div>
    </div>
  );
}

export default ToolTip;
